import { useIntersectionObserver } from '@chiroup/hooks';
import React from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { Button } from './Button';
import { Loading } from './Loading';

type Props = {
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        data: any[];
        lastKey?: string;
        skip?: number;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  small?: boolean;
  align?: 'start' | 'end' | 'center';
};

const EndOfList: React.FC<Props> = ({
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage = false,
  small = false,
  align = 'center',
}) => {
  const loadMoreButtonRef = React.useRef<HTMLDivElement>(null);

  const onIntersect = fetchNextPage || function () {};

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect,
    enabled: hasNextPage,
  });

  return (
    <div
      className={[
        `flex justify-${align} text-gray-400 px-3 pl-10`,
        small ? 'py-2' : 'py-8',
      ].join(' ')}
      ref={loadMoreButtonRef}
    >
      {isFetching || isFetchingNextPage ? (
        <Loading color="text-gray-400" className="-ml-1 mr-3" />
      ) : hasNextPage ? (
        <Button
          text="Load more"
          onClick={() => fetchNextPage?.()}
          disabled={!hasNextPage || isFetchingNextPage}
        />
      ) : (
        <div className="print:hidden">'No more results'</div>
      )}
    </div>
  );
};

export default EndOfList;
