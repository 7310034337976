import { signOut } from '@aws-amplify/auth';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import userService from '../services/user.service';
import { Languages } from '@chiroup/core/enums/Languages.enum';
import { Exercise } from '@chiroup/core/types/Exercise.type';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';
import { Advice } from '@chiroup/core/types/Advice.type';
import { Condition } from '@chiroup/core/types/Condition.type';

export type HealthcomMe = {
  ID: string;
  email: string;
  fname: string;
  mname: string;
  lname: string;
  language: Languages;
  username: string;
  advice: Advice[];
  conditions: Condition[];
  exercises: Exercise[];
  unsubscribe?: 1 | 0;
  primaryClinician?: string;
  fileAttachments?: {
    name: string;
    url: string;
  }[];
  clinic: {
    accentColor?: string;
    address1?: string;
    address2?: string;
    city?: string;
    country?: string;
    email?: string;
    facebook?: string;
    logo?: string;
    name?: string;
    phone?: string;
    primaryColor?: string;
    site?: string;
    state?: string;
    twitter?: string;
    youtube?: string;
    zip?: string;
    updatingIcons?: boolean;
  };
};

const useMe = () => {
  const [me, setMe] = useState<HealthcomMe | undefined>();
  const [downForMaintenance, setDownForMaintenance] = useState<{
    title: string;
    message: string;
  } | null>(null);
  const navigate = useNavigate();

  const getMe = useCallback(async () => {
    if (!me) {
      const res = await userService.me().catch((err) => {
        if (err?.response?.data?.statusCode === 503) {
          setDownForMaintenance(err?.response?.data);
        } else {
          signOut();
          navigate('/');
        }
      });
      setMe(res);
    }
  }, [me, navigate]);

  const updateMe = (key: keyof HealthcomMe, value: ValueOf<HealthcomMe>) => {
    setMe((prev?: HealthcomMe) =>
      prev
        ? {
            ...prev,
            [key]: value,
          }
        : undefined,
    );
  };

  return {
    me,
    updateMe,
    getMe,
    downForMaintenance,
  };
};

export default useMe;
