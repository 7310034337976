/**
 * Got tired of a try/catch around this feature everywhere.
 *
 * It has to look like a JSON string or no attempt is made to parse it on the
 * theory it is better to NOT throw an error if it can be prevented.
 *
 * @param jsonString
 * @returns
 */

const parse = (jsonString: string, def?: any) => {
  let resp = def || null;
  if (!jsonString) {
    return resp;
  }
  if (typeof jsonString === 'object') {
    return jsonString;
  }
  if (typeof jsonString !== 'string') {
    return resp;
  }
  if (jsonString.indexOf('{') !== 0 && jsonString.indexOf('[') !== 0) {
    return resp;
  }

  try {
    resp = JSON.parse(jsonString);
  } catch (e) {
    console.error('Error parsing JSON string:', jsonString);
  }
  return resp;
};

const stringify = (obj: any, def?: string | null) => {
  let resp = def || '';
  if (obj) {
    try {
      resp = JSON.stringify(obj);
    } catch (e) {
      console.error('Error stringifying JSON object:', obj);
    }
  }
  return resp;
};

const pretty = (obj: any, def?: string | null) => {
  let resp = def || '';
  if (obj) {
    try {
      resp = JSON.stringify(obj, null, 2);
    } catch (e) {
      console.error('Error stringifying JSON object:', obj);
    }
  }
  return resp;
};

/**
 * Just seemed we needed this.
 *
 * @param obj
 * @returns
 */
const clone = (obj: any, def?: any) => {
  if (!obj) return def ? def : obj;
  if (typeof obj !== 'object') return def ? def : obj;
  let resp = def || null;
  try {
    resp = JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error('Error cloning JSON object:', obj);
  }
  return resp;
};

/**
 * Just in case we need more functions on JSON objects that we use all the
 * time.
 */
export const ChiroUpJSON = {
  clone,
  parse,
  stringify,
  pretty,
};
