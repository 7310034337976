import React from 'react';

type Props = {
  children: React.ReactNode;
  width?: string;
};

const LoginContainer: React.FC<Props> = ({
  children,
  width = 'sm:max-w-md',
}) => {
  return (
    <div className="min-h-screen bg-white sm:bg-gray-50 sm:bg-none flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className={`mt-8 sm:mx-auto sm:w-full ${width} safe-area`}>
        <div className="bg-white py-8 px-4 sm:shadow sm:rounded-lg sm:px-10">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
