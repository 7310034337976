import React, { ChangeEvent, useEffect } from 'react';

type Props = {
  value?: number;
  onChange: (val: number) => void;
  language?: 'en' | 'es';
  min: number;
  max: number;
  left: string;
  right: string;
  step?: number;
  suffix?: string;
  hideValue?: boolean;
};

const Slider: React.FC<Props> = ({
  value,
  onChange,
  language = 'en',
  min,
  max,
  left,
  right,
  step = 1,
  suffix,
  hideValue = false,
}) => {
  useEffect(() => {
    const sheetToBeRemoved = document.getElementById('slider-styles');
    const sheetParent = sheetToBeRemoved?.parentNode;
    if (sheetParent) {
      sheetParent.removeChild(sheetToBeRemoved);
    }
    const sheet = document.createElement('style');
    sheet.setAttribute('id', 'slider-styles');
    sheet.innerHTML = `
		.range-slider__range::-webkit-slider-thumb {
			appearance: none;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: #77bd43;
			cursor: pointer;
			transition: background 0.15s ease-in-out;
		}
		.range-slider__range::-webkit-slider-thumb:hover {
			background: #77bd43;
		}
		.range-slider__range:active::-webkit-slider-thumb {
			background: #77bd43;
		}
		.range-slider__range::-moz-range-thumb {
			width: 20px;
			height: 20px;
			border: 0;
			border-radius: 50%;
			background: #77bd43;
			cursor: pointer;
			transition: background 0.15s ease-in-out;
		}
		.range-slider__range::-moz-range-thumb:hover {
			background: #77bd43;
		}
		.range-slider__range:active::-moz-range-thumb {
			background: #77bd43;
		}
		.range-slider__range:focus::-webkit-slider-thumb {
			box-shadow: 0 0 0 3px #fff, 0 0 0 6px #77bd43;
		}
		.range-slider__value {
			display: inline-block;
			position: relative;
			width: 60px;
			color: #fff;
			line-height: 20px;
			text-align: center;
			border-radius: 0.375rem;
			background: #77bd43;
			padding: 5px 10px;
		}
		`;
    document.body.appendChild(sheet);
    return () => {
      const sheetToBeRemoved = document.getElementById('slider-styles');
      const sheetParent = sheetToBeRemoved?.parentNode;
      if (sheetParent) {
        sheetParent.removeChild(sheetToBeRemoved);
      }
    };
  }, []);

  const onChangeSlider = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
  };

  return (
    <div>
      <div className="flex flex-row w-full justify-between p-2 text-xs text-gray-500 sm:text-sm md:text-md">
        <div>{left}</div>
        <div>{right}</div>
      </div>
      <div className="relative w-full rounded-full">
        <div className="range-slider">
          <input
            className="range-slider__range"
            type="range"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={onChangeSlider}
          />
          <div
            className="flex flex-row absolute w-full justify-between pointer-events-none"
            style={{ top: 0, padding: '13px 6px' }}
          >
            {Array.from(
              Array(step ? (max - min) / step + 1 : max - min + 1),
            ).map((item, i) => (
              <div key={i} className="rounded-full bg-white h-2 w-2 z-0" />
            ))}
          </div>
        </div>
      </div>
      {!hideValue && (
        <div className="flex justify-center mt-4">
          <span className="range-slider__value">
            {value}
            {suffix}
          </span>
        </div>
      )}
    </div>
  );
};

export default Slider;
