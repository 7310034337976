import React from 'react';

export enum ButtonColors {
  primary = 'primary',
  plain = 'plain',
}

type ButtonClassValues = {
  always: string;
  disabled: string;
  enabled: string;
};

type ButtonClasses = {
  [key in ButtonColors]: ButtonClassValues; // Note that "key in".
};

const buttonClasses: ButtonClasses = {
  primary: {
    always:
      'rounded-md shadow px-5 py-3 border border-transparent text-base font-medium transition duration-150 ease-in-out',
    disabled: 'text-gray-500 bg-gray-300 cursor-not-allowed',
    enabled: 'text-white focus:outline-none focus:ring hover:opacity-90',
  },
  plain: {
    always:
      'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium rounded-md text-gray-700 dark:text-darkGray-400 bg-white dark:bg-darkGray-700 hover:text-gray-500 dark:hover:text-darkGray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-700 transition duration-150 ease-in-out',
    disabled: '',
    enabled: '',
  },
};

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  color?: ButtonColors;
  icon?: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
};

const Button: React.FC<Props> = ({
  text,
  onClick,
  disabled,
  loading,
  fullWidth,
  color = ButtonColors.primary,
  icon,
  className,
  type = 'button',
}) => {
  // const {
  //   clinicInfo: { primaryColor, accentColor },
  // } = useContext(MeContext);
  const buttonClass = buttonClasses[color];

  return (
    <button
      type={type}
      onClick={onClick}
      className={[
        'inline-flex justify-center px-5 py-3',
        buttonClass.always,
        disabled ? buttonClass.disabled : buttonClass.enabled,
        fullWidth ? 'w-full' : '',
        className,
      ].join(' ')}
      style={
        disabled || color !== ButtonColors.primary
          ? {}
          : {
              backgroundColor: '#77bd43',
            }
      }
      disabled={disabled || loading}
    >
      {loading ? (
        <svg
          className={[
            'animate-spin -ml-1 mr-3 h-5 w-5',
            color === ButtonColors.plain ? 'text-gray-300' : 'text-white',
          ].join(' ')}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : icon ? (
        <svg
          className="-ml-1 mr-2 h-5 w-5 text-gray-500 dark:text-darkGray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          {icon}
        </svg>
      ) : null}
      {text}
    </button>
  );
};

export default Button;
