import { Checkbox, Input } from '@chiroup/components';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { useForm } from '@chiroup/hooks';
import { ExclamationTriangleIcon, EyeIcon } from '@heroicons/react/24/solid';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../common/Button';
import LoginContainer from './LoginContainer';
import PasswordValidationMeter from './PasswordValidationMeter';
import { confirmSignIn, signIn } from '@aws-amplify/auth';

const Login: React.FC = () => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const [user, setUser] = useState<any>();
  const { search } = useLocation();
  const { value, registerSubmit, onChange, errors, isSubmitting, patchValue } =
    useForm<{
      username: string;
      password: string;
      newPassword: string;
      verificationCode: string;
      rememberMe: boolean;
    }>(
      { username: '', password: '', rememberMe: false },
      {
        username: {
          required: {
            message: 'Username is required.',
          },
        },
        password: {
          required: {
            message: 'Password is required.',
          },
        },
        newPassword: {
          function: {
            value: (val) => {
              if (
                user?.nextStep?.signInStep === 'NEW_PASSWORD_REQUIRED' &&
                !val.newPassword
              ) {
                return 'New password is required.';
              }
              return false;
            },
          },
        },
        verificationCode: {
          function: {
            value: (val) => {
              if (
                user?.nextStep?.signInStep === 'SMS_MFA' &&
                !val.verificationCode
              ) {
                return 'Verification code is required.';
              }
              return false;
            },
          },
        },
      },
    );
  const [serverErrors, setServerErrors] = useState<FormError>();
  const [specialError, setSpecialError] = useState<string>();
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisibility] = useState(false);

  useEffect(() => {
    const queryParams = qs.parse(search);
    const fcid = queryParams?.fcid as string | undefined;
    if (!fcid) {
      return;
    }

    const credsStr = atob(fcid);
    const creds = JSON.parse(credsStr);

    if (creds?.user) {
      const patchStuff: {
        username: string;
        password?: string;
      } = {
        username: creds.user,
      };
      if (creds.pass) {
        patchStuff.password = creds.pass;
      }
      patchValue(patchStuff);
    }
  }, [patchValue, search]);

  const togglePasswordVisibility = (type: 'old' | 'new') => {
    type === 'old'
      ? setPasswordVisibility(!isPasswordVisible)
      : setNewPasswordVisibility(!isNewPasswordVisible);
  };

  const onSubmit = async () => {
    setServerErrors(undefined);
    setSpecialError(undefined);
    if (
      user?.nextStep?.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED' &&
      value.newPassword
    ) {
      const res = await confirmSignIn({
        challengeResponse: value.newPassword,
      });
      return res;
    } else if (
      (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE' ||
        user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') &&
      value.verificationCode
    ) {
      const queryParams = qs.parse(search);
      const emailToImpersonate = queryParams?.impersonate as string;
      const res = await confirmSignIn({
        challengeResponse: value.verificationCode,
        options: {
          userAttributes: {
            email: (value.username || '').trim(),
            emailToImpersonate,
          },
        },
      });
      return res;
    } else {
      setUser(undefined);
      const res = await signIn({
        username: (value.username || '').trim(),
        password: value.password || '',
        options: {
          clientMetadata: {
            email: (value.username || '').trim(),
          },
        },
      });
      setUser(res);
      return res as any;
    }
  };

  const onFail = async (err: any) => {
    const message = err?.message || '';
    if (message === 'User does not exist.') {
      setSpecialError('userNotFound');
    } else if (message === 'Incorrect username or password.') {
      setSpecialError('wrongPassword');
    } else {
      setServerErrors({
        message,
      });
    }
  };

  const onSuccess = async (res: any) => {
    // if (!res?.challengeName && value.rememberMe) {
    //   try {
    //     await Auth.rememberDevice();
    //   } catch (error) {
    //     console.error('Error remembering device', error);
    //   }
    // }
  };

  return (
    <LoginContainer>
      <h2 className="text-3xl font-extrabold text-gray-900">Sign in</h2>
      <form
        className="mt-8 space-y-6"
        onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}
      >
        {user?.nextStep?.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED' ? (
          <div>
            <Input
              label="New password"
              name="newPassword"
              onChange={onChange('newPassword')}
              autoComplete="new-password"
              type={isNewPasswordVisible ? 'text' : 'password'}
              value={value.newPassword}
              errors={errors.fieldErrors?.newPassword || serverErrors}
              ref={newPasswordRef}
              icon={
                <EyeIcon
                  className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-400 dark:text-darkGray-200 dark:hover:text-darkGray-400"
                  onClick={() => togglePasswordVisibility('new')}
                />
              }
              clickIcon
            />
            <PasswordValidationMeter
              password={value.newPassword}
              errors={errors.fieldErrors?.newPassword}
            />
          </div>
        ) : user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE' ? (
          <div>
            <p className="mt-1 text-sm text-gray-600">
              We've sent a text message to your phone number ending in{' '}
              <span className="font-bold">
                {user?.nextStep?.codeDeliveryDetails?.destination?.substr(-4)}
              </span>
              . Having issues receiving your code? Call us at{' '}
              <span className="font-bold">(844) 462-4476</span> for assistance.
            </p>
            <Input
              label="Verification code"
              name="verificationCode"
              onChange={onChange('verificationCode')}
              value={value.verificationCode}
              errors={errors.fieldErrors?.verificationCode || serverErrors}
              placeholder="Enter the verification code"
              inputMode="numeric"
              autoFocus
              autoComplete="one-time-code"
              pattern="[0-9]*"
            />
          </div>
        ) : (
          <>
            <div>
              <Input
                className="username"
                label="Username"
                name="username"
                onChange={onChange('username')}
                value={value.username}
                errors={errors.fieldErrors?.username}
                autoComplete="username"
                autoFocus
              />
              <Input
                className="password"
                label="Password"
                name="password"
                onChange={onChange('password')}
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete="current-password"
                value={value.password}
                errors={errors.fieldErrors?.password || serverErrors}
                ref={passwordRef}
                icon={
                  <EyeIcon
                    className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-400"
                    onClick={() => togglePasswordVisibility('old')}
                  />
                }
                clickIcon
              />
            </div>
            {specialError === 'userNotFound' && (
              <div className="p-4 border-l-4 border-yellow-400 bg-yellow-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      The username you entered does not exist.{' '}
                      <Link
                        to="/forgot-username"
                        className="font-medium text-yellow-700 underline hover:text-yellow-600"
                      >
                        Click here to retrieve your username
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            )}
            {specialError === 'wrongPassword' && (
              <div className="p-4 border-l-4 border-yellow-400 bg-yellow-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      The password you entered is not correct.{' '}
                      <Link
                        to="/forgot-password"
                        className="font-medium text-yellow-700 underline hover:text-yellow-600"
                      >
                        Click here to reset your password
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox
                  name="rememberMe"
                  value={value.rememberMe}
                  onChange={onChange('rememberMe')}
                  label="Remember me"
                />
              </div>
            </div>
          </>
        )}
        <Button
          className="submit"
          type="submit"
          text="Sign in"
          loading={isSubmitting}
          fullWidth
        />

        <div className="flex flex-col gap-2 mt-4">
          <div className="text-sm text-center">
            Forgot your{' '}
            <Link
              to="/forgot-username"
              className="font-medium text-primary-600 hover:text-primary-400"
            >
              username
            </Link>{' '}
            or{' '}
            <Link
              to="/forgot-password"
              className="font-medium text-primary-600 hover:text-primary-400"
            >
              password
            </Link>
            ?
          </div>
          <div className="mt-2 text-sm text-center">
            Need help logging in?{' '}
            <a
              href="https://mailchi.mp/225e5752148d/faq-healthcomio"
              className="font-medium text-primary-600 hover:text-primary-400"
              target="_blank"
              rel="noreferrer"
            >
              Click here for quick support
            </a>
            .
          </div>
        </div>
      </form>
    </LoginContainer>
  );
};

export default Login;
