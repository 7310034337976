import { Loading, Maintenance } from '@chiroup/components';
import React, { Suspense, useEffect } from 'react';
import LoadingPage from '../common/LoadingPage';
import { MeContext } from '../contexts/me.context';
import useMe from '../hooks/useMe';

type Props = {
  children: any;
};

const Container: React.FC<Props> = ({ children }) => {
  const { me, getMe, updateMe, downForMaintenance } = useMe();

  useEffect(() => {
    if (!me) {
      getMe();
    }
  }, [getMe, me]);

  return me ? (
    <Suspense fallback={<Loading />}>
      <MeContext.Provider
        value={{
          me,
          updateMe,
        }}
      >
        <div className="safe-area">
          <div className="bg-gray-50">
            {children}
            <div className="mt-12 pb-10 border-t border-gray-300 pt-8">
              <p className="text-base text-gray-400 text-center">
                Need help?{' '}
                <a
                  href="https://mailchi.mp/225e5752148d/faq-healthcomio"
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium hover:text-gray-600"
                >
                  Check out our Support Hub.
                </a>
              </p>
            </div>
          </div>
        </div>
      </MeContext.Provider>
    </Suspense>
  ) : downForMaintenance ? (
    <Maintenance
      title={downForMaintenance.title}
      message={downForMaintenance.message}
    />
  ) : (
    <LoadingPage />
  );
};

export default Container;
