export enum Genders {
  male = 'male',
  female = 'female',
  other = 'other',
  notDisclosed = 'notDisclosed',
}

export const GenderDisplay = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
  notDisclosed: 'Not disclosed',
};
