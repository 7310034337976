import { SELECT_OPTION_TYPE } from '../constants/globals';

export enum CodeSets {
  CPT = 'CPT',
  ICD10CM = 'ICD-10-CM',
  SERVICE = 'Service',
  DIAGNOSTIC = 'Diagnostic',
}

export type BillingCodeReference = {
  codeSet: CodeSets;
  code: string;
  clinicID?: number;
  description: string;
};

export type BillingCode = {
  ID: number;
  clinicID: number;
  codeSet: CodeSets;
  code: string;
  codeShortcut: string;
  description: string;
  billedAmount: number | string;
  variableBilledAmount: boolean;
  defaultModifiers?: string[];
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  deleted: boolean;
  payorIds?: number[];
  payors: BillingCodePayor[];
};

export type BillingCodeModifier = {
  clinicID: number;
  billingCodeID: number;
  modifier: string;
  payorID?: number;
};

export type BillingCodePayor = {
  clinicID: number;
  billingCodeID: number;
  payorID: number;
  allowedAmount: number;
  modifier1?: string;
  modifier2?: string;
  modifier3?: string;
  modifier4?: string;
  legalName?: string;
  customCode?: string;
};

export enum KnownClearingHouses {
  None = 'None',
  TriZetto = 'TriZetto',
  ClaimMD = 'ClaimMD',
  OfficeAlly = 'Office Ally',
  Waystar = 'Waystar',
}

export const KnownClearingHouseNames = {
  [KnownClearingHouses.None]: 'None',
  [KnownClearingHouses.TriZetto]: `TriZetto Provider Solutions`,
  [KnownClearingHouses.ClaimMD]: `CLAIMMD`,
  [KnownClearingHouses.OfficeAlly]: `Office Ally`,
  [KnownClearingHouses.Waystar]: `Waystar`,
};

export const KnownClearingHouseOptions = Object.keys(
  KnownClearingHouses,
).reduce((a, c) => {
  a.push({
    text: c,
    value: c,
  });
  return a;
}, [] as SELECT_OPTION_TYPE[]);

export type BillingProfile = {
  ID?: number;
  active: 0 | 1;
  type: 'Organization' | 'Individual';
  name: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  suffix?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  extension?: string;
  email?: string;
  contactName?: string;
  clearinghouse?: KnownClearingHouses;
  taxIdType: 'EIN' | 'SSN';
  taxId?: string;
  npi?: number;
  sameAsBillingProviderInfo?: boolean;
  taxonomyCode?: string;
  payToNonPersonEntity?: boolean;
  payToPerson?: boolean;
  payToAddressLine1?: string;
  payToAddressLine2?: string;
  payToCity?: string;
  payToState?: string;
  payToZipCode?: string;
  submitterType?: string;
  submitterName?: string;
  submitterFirstName?: string;
  submitterMiddleInitial?: string;
  submitterLastName?: string;
  submitterPhoneNumber?: string;
  submitterExtension?: string;
  submitterEmail?: string;
  submitterContactName?: string;
  providerDesignatedUsers?: string[];
  deleted: number;
  description?: string;
  trizettoUsername?: string;
  trizettoPassword?: string;
};
