import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { hcVerbiage } from '../common/verbiage';
import { MeContext } from '../contexts/me.context';
import { stripHtmlTags } from '@chiroup/core/functions/stripHtmlTags';

const Advices = () => {
  const { me } = useContext(MeContext);

  return (
    <div>
      <Header
        title={hcVerbiage.advice[me.language]}
        subtitle={hcVerbiage.adviceDescription[me.language]}
        breadcrumbs={[
          {
            title: hcVerbiage.advice[me.language],
            to: '/advice',
          },
        ]}
        noBack
      />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
        <div className="flex flex-col w-full">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-300">
              {me.advice?.map((advice) => (
                <li key={advice.ID}>
                  <Link
                    to={`/advice/${advice.ID}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        {!!advice.icon && (
                          <div className="flex-shrink-0">
                            <div className="w-12 h-12 items-center flex">
                              <img
                                className="w-full h-auto rounded-full"
                                src={advice.icon}
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                        <div className="min-w-0 flex-1 px-4 md:gap-4">
                          <div>
                            <p
                              className="text-sm font-medium truncate"
                              style={{
                                color: me?.clinic?.primaryColor,
                              }}
                            >
                              {advice.name}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate">
                                {stripHtmlTags(advice.descr)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {!!me.fileAttachments?.length && (
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
          <div className="flex flex-col w-full">
            <h2 className="mt-4 text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:tracking-tight">
              {hcVerbiage.files[me.language]}
            </h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md mt-4">
              <ul className="divide-y divide-gray-300">
                {me.fileAttachments?.map((file, i) => (
                  <li key={i}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block hover:bg-gray-50"
                    >
                      <div className="flex items-center pl-2 sm:pl-4 pr-4 py-4 sm:pr-6">
                        <div className="min-w-0 flex-1 flex items-center">
                          <div className="min-w-0 flex-1 px-4 md:gap-4">
                            <div>
                              <p
                                className="text-sm font-medium truncate"
                                style={{
                                  color: me?.clinic?.primaryColor,
                                }}
                              >
                                {file.name}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Advices;
