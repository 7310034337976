import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../contexts/me.context';
import { stripHtmlTags } from '@chiroup/core/functions/stripHtmlTags';
import { Exercise } from '@chiroup/core/types/Exercise.type';

type Props = {
  exercises: Exercise[];
};

const ExerciseList: React.FC<Props> = ({ exercises }) => {
  const { me } = useContext(MeContext);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-300">
        {exercises?.map((exercise) => (
          <li key={exercise.ID}>
            <Link
              to={`/exercises/${exercise.ID}`}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  {!!exercise.img && (
                    <div className="flex-shrink-0">
                      <div className="w-12 h-12 items-center flex relative">
                        <img
                          className="w-full h-auto rounded-full"
                          src={exercise.img}
                          alt=""
                        />
                        {/* <div
                                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full h-6 w-6 opacity-80"
                                  style={{
                                    backgroundColor: me?.clinic?.primaryColor,
                                  }}
                                >
                                  <PlayIcon className="h-6 w-6 text-white absolute left-0 top-0" />
                                </div> */}
                      </div>
                    </div>
                  )}
                  <div className="min-w-0 flex-1 px-4 md:gap-4">
                    <div>
                      <p
                        className="text-sm font-medium truncate"
                        style={{
                          color: me?.clinic?.primaryColor,
                        }}
                      >
                        {exercise.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="truncate">
                          {stripHtmlTags(exercise.descr)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExerciseList;
