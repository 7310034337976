import { useForm } from '@chiroup/hooks';
import { RadioGroup } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/solid';
import qs from 'query-string';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../common/Button';
import Slider from '../../common/Slider';
import userService from '../../services/user.service';
import LoginContainer from './LoginContainer';
import rateUs from '../../../assets/rate-us.png';
import { Buffer } from 'buffer';

const Survey: React.FC = () => {
  const { search } = useLocation();
  const [reviewURL, setReviewURL] = useState<string>();
  const [complete, setComplete] = useState(false);
  const { value, registerSubmit, onChange, isSubmitting } = useForm(
    {
      perBetter: 100,
      timesTreated: 0,
      recommend: 10,
      experience: 'Excellent',
    },
    {},
  );
  const [serverError, setServerError] = useState<string>();
  const [ratedUsWell, setRatedUsWell] = useState(false);

  const onSubmit = async () => {
    const { fcid } = qs.parse(search);
    if (typeof fcid !== 'string') {
      return;
    }
    const { patientID, surveyID } = JSON.parse(
      Buffer.from(fcid, 'base64').toString(),
    );
    if (
      !patientID ||
      !surveyID ||
      typeof value.perBetter !== 'number' ||
      typeof value.timesTreated !== 'number' ||
      typeof value.recommend !== 'number' ||
      typeof value.experience !== 'string'
    ) {
      return;
    }
    return userService.survey({
      perBetter: value.perBetter,
      timesTreated: value.timesTreated,
      recommend: value.recommend,
      experience: value.experience,
      patientId: patientID,
      surveyId: surveyID,
    });
  };

  const onSuccess = async (res: string) => {
    setComplete(true);
    if (value && res) {
      // If they rated their percent better as 30% or more AND they rated their recommend value as 9 or 10
      setRatedUsWell(
        !!value.perBetter &&
          value.perBetter >= 30 &&
          !!value.recommend &&
          value.recommend >= 9,
      );
      setReviewURL(res);
    }
  };

  const onFail = async (err: any) => {
    setServerError(err?.response?.data?.message || 'Failed to submit survey.');
  };

  return (
    <LoginContainer width="sm:max-w-2xl">
      <div className="safe-area">
        <h2 className="text-2xl font-extrabold text-gray-900">
          {complete
            ? 'Thank you for completing the survey!'
            : 'With regard to your primary condition:'}
        </h2>
        {complete ? (
          ratedUsWell ? (
            <div className="w-full mt-6">
              <a href={reviewURL} target="_blank" rel="noopener noreferrer">
                <img src={rateUs} alt="Rate us" className="w-full h-auto" />
              </a>
            </div>
          ) : (
            <div></div>
          )
        ) : (
          <form
            className="space-y-6 mt-8 flex flex-col gap-6"
            onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}
            autoComplete="off"
          >
            <div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start mb-4">
                  <span className="pr-2 bg-white text-sm text-gray-500">
                    How much better do you feel as compared to initially?
                  </span>
                </div>
              </div>
              <Slider
                value={value.perBetter}
                onChange={onChange('perBetter')}
                step={10}
                min={0}
                max={100}
                left={'0%'}
                right={'100%'}
                suffix="%"
              />
            </div>
            <div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start mb-4">
                  <span className="pr-2 bg-white text-sm text-gray-500">
                    How many treatments have you received?
                  </span>
                </div>
              </div>
              <Slider
                value={value.timesTreated}
                onChange={onChange('timesTreated')}
                step={1}
                min={0}
                max={15}
                left={'0'}
                right={'15'}
              />
            </div>
            <div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start mb-4">
                  <span className="pr-2 bg-white text-sm text-gray-500">
                    How would you rate your overall experience in our office?
                  </span>
                </div>
              </div>
              <div className="w-full px-4 py-4">
                <div className="mx-auto w-full max-w-md">
                  <RadioGroup
                    value={value.experience}
                    onChange={(val: string) => onChange('experience')(val)}
                  >
                    <div className="space-y-2">
                      {['Excellent', 'Good', 'Fair', 'Poor'].map((plan) => (
                        <RadioGroup.Option
                          key={plan}
                          value={plan}
                          className={({ active, checked }) =>
                            `${
                              active
                                ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-300'
                                : 'border border-gray-300'
                            }
                  ${checked ? 'bg-primary-600 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                          }
                        >
                          {({ active, checked }) => (
                            <div className="flex w-full items-center justify-between">
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-medium  ${
                                      checked ? 'text-white' : 'text-gray-900'
                                    }`}
                                  >
                                    {plan}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              {checked && (
                                <div className="shrink-0 text-white">
                                  <CheckIcon className="h-6 w-6" />
                                </div>
                              )}
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start mb-4">
                  <span className="pr-2 bg-white text-sm text-gray-500">
                    How likely is it that you would recommend us to a friend or
                    colleague?
                  </span>
                </div>
              </div>
              <Slider
                value={value.recommend}
                onChange={onChange('recommend')}
                step={1}
                min={0}
                max={10}
                left={'Not at all likely'}
                right={'Extremely likely'}
                hideValue
              />
            </div>
            {!!serverError && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      Error submitting survey
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul className="list-disc pl-5 space-y-1">
                        <li>{serverError}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Button
              className="submit"
              type="submit"
              text="Submit"
              loading={isSubmitting}
              fullWidth
            />
          </form>
        )}
      </div>
    </LoginContainer>
  );
};

export default Survey;

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
