export const hasProperty = (obj: any, prop: string): boolean => {
  if (!obj) {
    return false;
  }

  try {
    const hasProperty = Object.prototype.hasOwnProperty.call(obj, prop);
    return hasProperty;
  } catch (err) {
    return false;
  }
};
