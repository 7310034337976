import React, { Dispatch, SetStateAction } from 'react';
import { Route, Routes } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';
import Login from './Login';
import Survey from './Survey';

type Props = {
  setAuthState: Dispatch<SetStateAction<string | undefined>>;
  setUser: Dispatch<SetStateAction<object | undefined>>;
};

const LoggedOut: React.FC<Props> = ({ setAuthState, setUser }) => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/forgot-username" element={<ForgotUsername />} />
      <Route path="/survey" element={<Survey />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default LoggedOut;
