import {
  FormErrors,
  FormFieldErrors,
  FormError,
} from '@chiroup/core/types/ErrorResponse.type';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import { isEmpty } from '@chiroup/core/functions/isEmpty';
import { isValid } from '@chiroup/core/functions/isValid';

export type SubmitReturnType<T, RT = void> = Promise<
  Partial<T> | Partial<RT> | undefined | Response
>;

type ReturnType<T, RT = void> = {
  value: Partial<T>;
  registerSubmit: (
    fnSubmit: (values: Partial<T>) => SubmitReturnType<T, RT> | undefined,
    // We don't know what the response looks like...we could send in a type, but not sure it is worth the effort?
    {
      onSuccess,
      onFail,
    }: { onSuccess?: (response: any) => void; onFail?: (errors: any) => void },
  ) => (e?: any) => void;
  isDirty: boolean;
  isSubmitting: boolean;
  setValue: (values: Partial<T>) => void;
  patchValue: (values: Partial<T>) => void;
  reset: () => void;
  errors: FormErrors;
  onChange: (key: keyof T) => (val: ValueOf<T>) => void;
  markAsDirty: () => void;
  patchValueClean: (values: Partial<T>) => void;
  setFormValue: any;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<FormErrors>>;
  defaultValidators: {
    [key: string]: (value: Partial<T>) => false | string;
  };
};

type FieldValidationRule<T> = {
  required?: { message: string };
  pattern?: {
    value: RegExp;
    message: string;
  };
  maxLength?: { value: number; message: string };
  minLength?: { value: number; message: string };
  function?: { value: (values: Partial<T>) => false | string };
};

export function useForm<T, RT = void>(
  initialValue: Partial<T>,
  fieldValidationRules?: {
    [key in keyof T]?: FieldValidationRule<T>;
  },
): ReturnType<T, RT> {
  const [formValue, setFormValue] = useState<Partial<T>>(initialValue);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const updateErrors = useCallback(
    (newValues: Partial<T>) => {
      if (fieldValidationRules) {
        const validation = Object.entries(fieldValidationRules) as [
          keyof T,
          FieldValidationRule<T>,
        ][];

        const newErrors = validation.reduce(
          (obj: FormFieldErrors, [key, rule]) => {
            const field = key as string;
            const val = newValues[key];

            // Required
            if (
              rule.required &&
              (Array.isArray(val) ? !val?.length : isEmpty(val))
            ) {
              obj[field] = { message: rule.required.message };
              return obj;
            }

            // Min length
            if (
              isValid(val) &&
              rule.minLength &&
              rule.minLength.value > String(val).length
            ) {
              const err =
                rule.minLength.message ||
                `This field must be at least ${rule.minLength.value} characters.`;
              obj[field] = {
                message: obj[field] ? `${obj[field]}\n${err}` : err,
              };
            }

            // Max length
            if (
              isValid(val) &&
              rule.maxLength &&
              rule.maxLength.value < String(val).length
            ) {
              const err =
                rule.maxLength.message ||
                `This field must be at most ${rule.maxLength.value} characters.`;
              obj[field] = {
                message: obj[field] ? `${obj[field]}\n${err}` : err,
              };
            }

            // Regex
            if (
              isValid(val) &&
              rule.pattern?.value &&
              !rule.pattern?.value.test(String(val))
            ) {
              obj[field] = {
                message:
                  rule.pattern.message ||
                  'This field has criteria that have not been met.',
              };
              return obj;
            }

            // Function
            if (rule.function?.value) {
              const fnVal = rule.function.value(newValues);
              if (fnVal) {
                obj[field] = {
                  message: fnVal || 'Something went wrong.',
                };
                return obj;
              }
            }

            return obj;
          },
          {},
        );
        setErrors({ fieldErrors: newErrors });
        return newErrors;
      }
      return {};
    },
    [fieldValidationRules],
  );

  const defaultValidators = {
    phone: (value: Partial<T>) => {
      const { phone } = value as any;
      if (
        phone?.[1] === '1' &&
        phone?.split(' ')?.[0]?.length === 6 &&
        phone?.length < 13
      ) {
        return 'Phone number must be 7 digits.';
      } else if (phone?.[1] === '1' && phone?.length < 13) {
        return 'Phone number must be 10 digits.';
      }
      return false;
    },
  };

  const markAsDirty = useCallback(() => {
    setIsDirty(true);
  }, []);

  const patchValue = useCallback(
    (values: Partial<T>) => {
      setFormValue((prev) => ({ ...prev, ...values }));

      if (Object.keys(values)?.length) {
        markAsDirty();
      }
    },
    [markAsDirty],
  );

  /**
   * [2023-08-25.1303 by Brian]
   * Added some support for multi-dimentional arrays, hopefully ;-)
   * Warning...the multi-dimensional code only works one level deep.
   * Probably has something to do with patchValue, but I did not
   * investigate. Too many rabbit holes visited already. The old
   * behavior should still work fine.
   *
   * @param key
   * @returns
   */
  const onChange = useCallback(
    (key: keyof T) => {
      return (val: ValueOf<T>) => {
        const obj: Partial<T> = {} as Partial<T>;
        /**
         * Detect if the value we're dealing with is an array.
         */
        const pcs = (typeof key === 'string' ? key : '')
          .split(/\[|\]/)
          .filter((a) => !!a) as [keyof T, string, string];
        if (pcs.length > 1) {
          const idx = Number.parseInt(pcs[1]),
            idx2 = Number.parseInt(pcs[2]),
            varb = pcs[0] as keyof T;

          if (idx2) {
            obj[varb] = [].concat(formValue[varb] as any) as any;
            (obj[varb] as any)[idx] = [].concat(
              (formValue[varb] as any)[idx] as any,
            ) as any;
            (obj[varb] as any)[idx][idx2] = val;
          } else {
            obj[varb] = [].concat(formValue[varb] as any) as any;
            (obj[varb] as any)[idx] = val;
          }
        } else {
          obj[key] = val;
        }
        patchValue(obj);
      };
    },
    [formValue, patchValue],
  );

  const markAsClean = () => {
    setIsDirty(false);
  };

  const setValue = useCallback(
    (newValues: Partial<T>) => {
      setFormValue(newValues);
      markAsDirty();
    },
    [markAsDirty],
  );

  const patchValueClean = useCallback((values: Partial<T>) => {
    setFormValue((prev) => ({
      ...prev,
      ...values,
    }));
  }, []);

  const reset = useCallback(() => {
    setFormValue(initialValue);
  }, [initialValue]);

  const registerSubmit = (
    fnSubmit: (values: Partial<T>) => SubmitReturnType<T, RT> | undefined,
    {
      onSuccess,
      onFail,
    }: {
      onSuccess?: (
        response: Partial<T> | Partial<RT> | undefined | Response,
      ) => void;
      onFail?: (errors: any) => void;
    },
  ) => {
    return async (e?: any) => {
      e?.preventDefault();
      e?.stopPropagation();
      const fieldErrors = updateErrors(formValue);

      if (!fieldErrors || !Object.keys(fieldErrors).length) {
        try {
          setIsSubmitting(true);
          setErrors({});
          const res = await fnSubmit(formValue);
          if ((res as any)?.skipMarkAsClean !== true) {
            markAsClean();
          }
          if ((res as any)?.skipSuccess !== true) {
            onSuccess?.(res);
          }
          setIsSubmitting(false);
        } catch (err: any) {
          console.error({ err });

          /**
           * This is a _very_ specific check for duplicate keys. The key has to be
           * specifically named and that name has to be a field in the form.
           * Otherwise, the original logic applies. There may be a better way to do
           * this...open to suggestions. Was just going for some extra credit with
           * displaying more useful errors to the user.
           *
           * This will break if the constraints aren't named regularly and probably
           * will fail if the value has a single quote in it.
           */

          if (err?.response?.data?.message) {
            const msg = err?.response?.data?.message,
              re = /Duplicate entry '([^']+)' for key '([^_]+)_uniq'/i,
              groups = msg.match(re);
            if (groups?.length === 3) {
              const [, value, key] = groups;

              if (hasProperty(formValue, key)) {
                setErrors({
                  form: [],
                  fieldErrors: {
                    [key]: {
                      message: `The value "${value}" is already in use.`,
                    },
                  },
                });
                return;
              }
            }
          }
          setIsSubmitting(false);
          if (err?.response?.data) {
            const { form = [], ...other } = err.response.data;
            const otherForFieldErrors = other?.fieldErrors || other;
            const fieldErrors = Object.keys(otherForFieldErrors || {}).reduce(
              (obj: { [key: string]: FormError }, prop) => {
                if (Object.prototype.hasOwnProperty.call(formValue, prop)) {
                  // If the field exists, put the error on the field
                  obj[prop] = otherForFieldErrors[prop];
                } else {
                  // If the field doesn't exist, put the error on the form
                  form.push({
                    message:
                      typeof otherForFieldErrors[prop]?.message === 'string'
                        ? otherForFieldErrors[prop]?.message
                        : typeof otherForFieldErrors[prop] === 'string'
                          ? otherForFieldErrors[prop]
                          : JSON.stringify(
                              otherForFieldErrors[prop]?.message ||
                                otherForFieldErrors[prop],
                            ),
                  });
                }
                return obj;
              },
              {},
            );
            setErrors(err.response.data);

            const errorsToUse = {
              form,
              fieldErrors,
            };
            setErrors(errorsToUse);
          }
          onFail?.(err);
        }
      }
    };
  };

  return {
    value: formValue,
    registerSubmit,
    isSubmitting,
    isDirty,
    setValue,
    patchValue,
    reset,
    errors,
    onChange,
    markAsDirty,
    patchValueClean,
    setFormValue,
    setIsSubmitting,
    setErrors,
    defaultValidators,
  };
}
