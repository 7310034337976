/**
 * [2023-11-10.1439 by Brian]
 *    Added support for a 'style' parameter. I keep wrapping this thing with
 *    the same '<div>' and parameters and thought a shortcut might be nice.
 *    Should be possible to add as many as we like.
 *
 *    Also added a 'flag' parameter to control its visibility. Just a shortcut
 *    to some boilerplate.
 *
 * [2023-07-14.1314 by Brian]
 *    Added support for a 'percent' parameter. Maintained backwards compatibility
 *    by still supporting size. However, the size does not work the way I expected
 *    it to. I went from size 12 to 14 and it filled the entire screen. I
 *    discovered that scaling the width by a percent would allow the size to be
 *    controlled without mucking up the layout.
 */
import React from 'react';

type Props = {
  color?: string;
  size?: number;
  percent?: number;
  className?: string;
  style?: string;
  flag?: boolean;
  top?: string;
  left?: string;
};

export const Loading: React.FC<Props> = ({
  color = 'text-white',
  size = 12,
  percent,
  className = '',
  style = '',
  flag = true,
  top = '15%',
  left = '45%',
}) => {
  if (!flag) return null;

  if (style === 'absolute-standard-gray') {
    return (
      <div className="flex justify-center p-4 absolute" style={{ top, left }}>
        <LoadingItem color={`text-gray-400`} size={size} percent={percent} />
      </div>
    );
  }

  if (style === 'standard-gray') {
    return (
      <div className="flex justify-center p-4">
        <LoadingItem color={`text-gray-400`} size={size} percent={percent} />
      </div>
    );
  }

  if (style === 'tiny-inline') {
    return (
      <div className="flex justify-start w-4 -mt-3 -mb-3">
        <LoadingItem color={color} size={size} />
      </div>
    );
  }

  if (style === 'tiny-inline-gray') {
    return (
      <div className="flex justify-start w-4 -mt-3 -mb-3">
        <LoadingItem color={`text-gray-400`} size={size} />
      </div>
    );
  }

  if (style === 'tiny-inline-white') {
    return (
      <div className="flex justify-start w-4 -mt-3 -mb-3">
        <LoadingItem color={`text-white`} size={size} />
      </div>
    );
  }

  return (
    <LoadingItem
      color={color}
      size={size}
      percent={percent}
      className={className}
    />
  );
};

const LoadingItem: React.FC<Props> = ({
  color = 'text-white',
  size = 12,
  percent,
  className = '',
}) => {
  return (
    <svg
      style={percent ? { width: `${percent}%` } : {}}
      className={`animate-spin ${
        !percent ? 'h-' + size + ' w-' + size : ''
      } ${color} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};
