import {
  get as amplifyGet,
  put as amplifyPut,
  patch as amplifyPatch,
  post as amplifyPost,
  del as amplifyDel,
} from 'aws-amplify/api';

const get = async <T = any>(
  apiName: any,
  path: any,
  options: any,
): Promise<T> => {
  if (options.queryParams) {
    // Get rid of any undefined values
    Object.entries(options.queryParams).forEach(([key, value]) => {
      if (value === undefined) {
        delete options.queryParams[key];
      }
    });
  }
  const restOperation = amplifyGet({
    apiName,
    path,
    options,
  });
  const response = await restOperation.response.catch((err) => {
    let body = {};
    if (err?._response?.body) {
      body = JSON.parse(err._response.body);
    }
    if (!err?._response?.body && err?.message) {
      body = {
        message: err.message,
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        data: {
          statusCode:
            err?._response?.statusCode || err?.$metadata?.httpStatusCode,
          ...body,
        },
      },
    };
  });
  let returnResponse = null;
  if (response.body) {
    const json = await response.body.json().catch(() => {});
    returnResponse = json || null;
  }
  return returnResponse as T;
};

const put = async <T = any>(
  apiName: any,
  path: any,
  options: any,
): Promise<T> => {
  const restOperation = amplifyPut({
    apiName,
    path,
    options,
  });
  const response = (await restOperation.response.catch((err) => {
    let body = {};
    if (err?._response?.body) {
      body = JSON.parse(err._response.body);
    }
    if (!err?._response?.body && err?.message) {
      body = {
        message: err.message,
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        data: {
          statusCode:
            err?._response?.statusCode || err?.$metadata?.httpStatusCode,
          ...body,
        },
      },
    };
  })) as any;

  let returnResponse = null;
  if (response.body) {
    const json = await response.body.json().catch(() => {});
    returnResponse = json || null;
  }
  return returnResponse as T;
};

const patch = async <T = any>(
  apiName: any,
  path: any,
  options: any,
): Promise<T> => {
  const restOperation = amplifyPatch({
    apiName,
    path,
    options,
  });
  const response = (await restOperation.response.catch((err) => {
    let body = {};
    if (err?._response?.body) {
      body = JSON.parse(err._response.body);
    }
    if (!err?._response?.body && err?.message) {
      body = {
        message: err.message,
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        data: {
          statusCode:
            err?._response?.statusCode || err?.$metadata?.httpStatusCode,
          ...body,
        },
      },
    };
  })) as any;
  let returnResponse = null;
  if (response.body) {
    const json = await response.body.json().catch(() => {});
    returnResponse = json || null;
  }
  return returnResponse as T;
};

const post = async <T = any>(
  apiName: any,
  path: any,
  options: any,
  requestOptions: {
    type?: 'json' | 'blob' | 'text';
  } = {},
): Promise<T> => {
  const restOperation = amplifyPost({
    apiName,
    path,
    options,
  });
  const response = (await restOperation.response.catch((err) => {
    let body = {};
    if (err?._response?.body) {
      body = JSON.parse(err._response.body);
    }
    if (!err?._response?.body && err?.message) {
      body = {
        message: err.message,
      };
    }
    if (typeof body === 'string') body = { message: body };
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        data: {
          statusCode:
            err?._response?.statusCode || err?.$metadata?.httpStatusCode,
          ...body,
        },
      },
    };
  })) as any;
  let returnResponse = null;
  if (response.body) {
    let res = null;
    if (requestOptions?.type === 'blob') {
      res = await response.body.blob().catch(() => {});
    } else if (requestOptions?.type === 'text') {
      res = await response.body.text().catch(() => {});
    } else {
      res = await response.body.json().catch(() => {});
    }
    returnResponse = res;
  }
  return returnResponse as T;
};

const del = async <T = any>(
  apiName: any,
  path: any,
  options: any,
): Promise<T> => {
  if (options.body) {
    console.error(
      'DELETE requests cannot have a body via the amplify library in V6...smh...so this body is not going to be sent.',
      options.body,
    );
  }
  const restOperation = amplifyDel({
    apiName,
    path,
    options,
  });
  const response = (await restOperation.response.catch((err) => {
    let body = {};
    if (err?._response?.body) {
      body = JSON.parse(err._response.body);
    }
    if (!err?._response?.body && err?.message) {
      body = {
        message: err.message,
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        data: {
          statusCode:
            err?._response?.statusCode || err?.$metadata?.httpStatusCode,
          ...body,
        },
      },
    };
  })) as any;
  return response as T;
};

export const ChiroUpAPI = {
  get,
  put,
  patch,
  post,
  del,
};
