import { Popover } from '@headlessui/react';
import { useContext } from 'react';
import { MeContext } from '../contexts/me.context';
import userService from '../services/user.service';
import { hcVerbiage } from './verbiage';
import { signOut } from '@aws-amplify/auth';

const UserNavigation = () => {
  const { me, updateMe } = useContext(MeContext);

  const logout = async () => {
    await signOut();
  };

  const patientSurveysChange = async () => {
    const newValue = me.unsubscribe ? 0 : 1;
    await userService.surveyEmails(!!newValue);
    updateMe('unsubscribe', newValue);
  };

  return (
    <div className="mt-3 px-2 space-y-1">
      <a
        href="https://help.chiroup.com/en_US/security-privacy/healthcomio-terms-of-use"
        target="_blank"
        rel="noreferrer"
      >
        <Popover.Button className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
          {hcVerbiage.termsOfUse[me.language]}
        </Popover.Button>
      </a>
      <a
        href="https://help.chiroup.com/en_US/security-privacy/healthcomio-privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        <Popover.Button className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
          {hcVerbiage.privacyPolicy[me.language]}
        </Popover.Button>
      </a>
      <Popover.Button
        onClick={patientSurveysChange}
        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
      >
        {
          hcVerbiage[me.unsubscribe ? 'resubscribe' : 'unsubscribe'][
            me.language
          ]
        }
      </Popover.Button>
      <Popover.Button
        onClick={logout}
        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
      >
        {hcVerbiage.signOut[me.language]}
      </Popover.Button>
    </div>
  );
};

export default UserNavigation;
