import { useOnClickOutside } from '@chiroup/hooks';
import React, { useCallback, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

type Props = {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  className?: string;
};

const ColorPickerComponent: React.FC<Props> = ({
  label,
  value,
  onChange,
  className = '',
}) => {
  const popover = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useOnClickOutside(popover, close);

  return (
    <div className={`flex items-center gap-2 ${className || ''}`}>
      <div className="relative">
        <div
          className="w-6 h-6 rounded-md cursor-pointer border border-gray-300"
          style={{ backgroundColor: value || '#ffffff' }}
          onClick={() => toggle(true)}
        />

        {isOpen && (
          <div
            className="absolute left-0 rounded-md shadow-md z-10 flex flex-col bg-white p-4 gap-4"
            ref={popover}
            style={{ top: 'calc(100% + 2px)' }}
          >
            <HexColorPicker color={value} onChange={onChange} />
            <HexColorInput
              color={value}
              onChange={onChange}
              className="block w-full p-1 border border-gray-300 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2 focus:border-primary-500 focus:ring-primary-500 outline-none"
            />
          </div>
        )}
      </div>
      <label className="block text-sm leading-5 text-gray-900 dark:text-darkGray-400">
        {label}
      </label>
    </div>
  );
};

export const ColorPicker = React.memo(ColorPickerComponent);
