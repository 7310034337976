import { Input } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { EyeIcon } from '@heroicons/react/24/solid';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../common/Button';
import userService from '../../services/user.service';
import LoginContainer from './LoginContainer';
import PasswordValidationMeter from './PasswordValidationMeter';
import { confirmResetPassword, signIn } from '@aws-amplify/auth';

export enum ForgotPasswordStep {
  sendCode,
  setPassword,
}

const ForgotPassword: React.FC = () => {
  const [cantReset, setCantReset] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { value, registerSubmit, onChange, errors, isSubmitting } = useForm(
    { username: '', code: '', password: '' },
    {
      username: {
        function: {
          value: (val) => {
            if (step === ForgotPasswordStep.sendCode && !val.username) {
              return 'Username is required.';
            }
            return false;
          },
        },
      },
      code: {
        function: {
          value: (val) => {
            if (step === ForgotPasswordStep.setPassword && !val.code) {
              return 'Enter the verification code you received in your email.';
            }
            return false;
          },
        },
      },
      password: {
        function: {
          value: (val) => {
            if (step === ForgotPasswordStep.setPassword && !val.password) {
              return 'New password is required.';
            }
            return false;
          },
        },
        pattern: PasswordValidator,
      },
    },
  );
  const [step, setStep] = useState<ForgotPasswordStep>(
    ForgotPasswordStep.sendCode,
  );
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  const onSubmit = async () => {
    if (!value.username) {
      return;
    }
    if (step === ForgotPasswordStep.sendCode) {
      try {
        const canResetRes = await userService.canResetPassword(
          value.username.toLowerCase(),
        );
        if (!canResetRes?.canResetPassword) {
          setCantReset(true);
          return;
        }
        const res = await userService.forgotPassword(
          value.username.toLowerCase(),
        );
        setStep(ForgotPasswordStep.setPassword);
        return res;
      } catch (err) {
        console.error({ err });
      }
    } else if (step === ForgotPasswordStep.setPassword) {
      if (!value.code || !value.password) {
        return;
      }
      try {
        const res = await confirmResetPassword({
          username: value.username.toLowerCase(),
          confirmationCode: value.code,
          newPassword: value.password,
        });
        await signIn({
          username: value.username.toLowerCase(),
          password: value.password,
        });
        navigate('/');
        return res;
      } catch (err) {
        console.error({ err });
      }
    }
  };

  return (
    <LoginContainer>
      <h2 className="text-3xl font-extrabold text-gray-900">
        Reset your password
      </h2>
      <p className="mt-1 text-sm text-gray-600">
        Remember?{' '}
        <Link
          to="/"
          className="font-medium text-primary-500 hover:text-primary-300"
        >
          Sign in
        </Link>
      </p>
      <form
        className="mt-8 space-y-6"
        onSubmit={registerSubmit(onSubmit, {})}
        autoComplete="off"
      >
        {cantReset ? (
          <div>
            Hmm, our system doesn’t recognize your username…Please contact your
            clinic during business hours for direct help to reset your username
            & password.
          </div>
        ) : step === ForgotPasswordStep.sendCode ? (
          <Input
            label="Username"
            name="username"
            onChange={onChange('username')}
            value={value.username}
            errors={errors.fieldErrors?.username}
            hint={
              <p className="mt-1 text-sm text-gray-600">
                Forgot your username?{' '}
                <Link
                  to="/forgot-username"
                  className="font-medium text-primary-500 hover:text-primary-300"
                >
                  Click here
                </Link>
              </p>
            }
          />
        ) : (
          <div>
            <p className="p-4 mt-1 mb-6 text-sm text-gray-600 bg-gray-100 rounded-md">
              You will receive a code in your email. Enter the code below to
              reset your password. If you have difficulty finding the code,
              please contact the clinic to reset your password.
            </p>
            <Input
              label="Verification code"
              name="code"
              onChange={onChange('code')}
              value={value.code}
              errors={errors.fieldErrors?.code}
              autoComplete="off"
            />
            <Input
              label="New password"
              name="password"
              onChange={onChange('password')}
              type={isPasswordVisible ? 'text' : 'password'}
              value={value.password}
              errors={errors.fieldErrors?.password}
              autoComplete="new-password"
              ref={passwordRef}
              icon={
                <EyeIcon
                  className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-400"
                  onClick={togglePasswordVisibility}
                />
              }
              clickIcon
            />
            <PasswordValidationMeter
              password={value.password}
              errors={errors.fieldErrors?.password}
            />
          </div>
        )}

        {cantReset ? (
          <Button
            text="Back to login"
            onClick={() => {
              navigate('/');
            }}
            fullWidth
          />
        ) : (
          <Button
            type="submit"
            text={
              step === ForgotPasswordStep.sendCode
                ? 'Send code'
                : 'Change password'
            }
            loading={isSubmitting}
            fullWidth
          />
        )}
      </form>
    </LoginContainer>
  );
};

export default ForgotPassword;

export const PasswordValidator = {
  value: /^(?=.*\d)(?=.*\W+)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  message: 'Password minimums not met',
  // 'Password must contain at least one upper case letter, lower case letter, special character (!@#$%^&*<>/), number, and be at least 8 characters in length.',
};
