export const hcVerbiage = {
  previous: {
    en: 'Previous',
    es: 'Previo',
  },
  next: {
    en: 'Next',
    es: 'Continuar',
  },
  complete: {
    en: 'Complete',
    es: 'Completo',
  },
  notify: {
    en: 'Exercises completed',
    es: 'Ejercicios completados',
  },
  thankYou: {
    en: 'Thanks! Your doctor has been notified.',
    es: '¡Gracias! Su médico ha sido notificado.',
  },
  cancel: {
    en: 'Cancel',
    es: 'Cancelar',
  },
  close: {
    en: 'Close',
    es: 'Cerrar',
  },
  submit: {
    en: 'Submit',
    es: 'Enviar',
  },
  exercises: {
    en: 'Exercises',
    es: 'Ejercicios',
  },
  exercisesDescription: {
    en: 'Your prescribed exercises are an important part of your treatment plan.',
    es: 'Sus ejercicios prescritos son una parte importante de su plan de tratamiento.',
  },
  completeExercises: {
    en: 'Exercises completed',
    es: 'Ejercicios completados',
  },
  pleaseRate: {
    en: 'Please rate your pain after completion of the exercises.',
    es: 'Califique su dolor después de completar los ejercicios.',
  },
  noPain: {
    en: 'No pain',
    es: 'Sin dolor',
  },
  mild: {
    en: 'Mild',
    es: 'Mild',
  },
  moderate: {
    en: 'Moderate',
    es: 'Moderado',
  },
  severe: {
    en: 'Severe',
    es: 'Severo',
  },
  verySevere: {
    en: 'Very severe',
    es: 'Muy severo',
  },
  worst: {
    en: 'Worst',
    es: 'Peor',
  },
  conditions: {
    en: 'Conditions',
    es: 'Condiciones',
  },
  conditionsDescription: {
    en: 'Select a condition below to view more detail.',
    es: 'Seleccione una condición a continuación para ver más detalles.',
  },
  advice: {
    en: 'Advice',
    es: 'Consejo',
  },
  adviceDescription: {
    en: 'Select a piece of advice on daily living below to view more detail.',
    es: 'Seleccione un consejo sobre la vida diaria a continuación para ver más detalles.',
  },
  files: {
    en: 'Files',
    es: 'Archivos',
  },
  myClinic: {
    en: 'My Clinic',
    es: 'Mi Clínica',
  },
  clinic: {
    en: 'Clinic',
    es: 'Clínica',
  },
  clinicContact: {
    en: 'Contact information for the clinic.',
    es: 'Información de contacto de la clínica.',
  },
  call: {
    en: 'Call the clinic',
    es: 'Llame a la clínica',
  },
  callDescription: {
    en: 'Call {{clinicName}} at {{phone}} to make an appointment or speak to our staff.',
    es: 'Llame a {{clinicName}} al {{phone}} para programar una cita o hablar con nuestro personal.',
  },
  directions: {
    en: 'Get directions',
    es: 'Obtener las direcciones',
  },
  directionsDescription: {
    en: 'We are conveniently located at {{address}}. Click here to get directions to our location.',
    es: 'Estamos convenientemente ubicados en {{address}}. Haga clic aquí para obtener indicaciones para llegar a nuestra ubicación.',
  },
  email: {
    en: 'Email us',
    es: 'Envíanos un correo electrónico',
  },
  emailDescription: {
    en: 'Email us for information about the clinic and if you have any questions.',
    es: 'Envíenos un correo electrónico para obtener información sobre la clínica y si tiene alguna pregunta.',
  },
  site: {
    en: 'Visit our website',
    es: 'Visite nuestro sitio web',
  },
  siteDescription: {
    en: 'Visit our website for more information about {{clinicName}}. We offer a wide range of services and resources.',
    es: 'Visite nuestro sitio web para obtener más información sobre {{clinicName}}. Ofrecemos una amplia gama de servicios y recursos.',
  },
  facebookDescription: {
    en: 'Visit our Facebook page to connect with us and stay up to date with our clinic.',
    es: 'Visite nuestra página de Facebook para conectarse con nosotros y mantenerse al día con nuestra clínica.',
  },
  twitterDescription: {
    en: 'Visit our Twitter page to connect with us and stay up to date with our clinic.',
    es: 'Visite nuestra página de Twitter para conectarse con nosotros y mantenerse al día con nuestra clínica.',
  },
  youtubeDescription: {
    en: 'Visit our YouTube page to view videos and information about {{clinicName}}.',
    es: 'Visite nuestra página de YouTube para ver videos e información sobre {{clinicName}}.',
  },
  settings: {
    en: 'Settings',
    es: 'Ajustes',
  },
  termsOfUse: {
    en: 'Terms of Use',
    es: 'Términos de Uso',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    es: 'Política de Privacidad',
  },
  unsubscribe: {
    en: 'Unsubscribe from survey emails',
    es: 'Cancelar suscripción a correos electrónicos de encuestas',
  },
  resubscribe: {
    en: 'Resubscribe to survey emails',
    es: 'Suscribirse a correos electrónicos de encuestas',
  },
  signOut: {
    en: 'Sign out',
    es: 'Cerrar sesión',
  },
  openUserMenu: {
    en: 'Open user menu',
    es: 'Abrir menú de usuario',
  },
  clinicalName: {
    en: 'Clinical name',
    es: 'Nombre clínico',
  },
};
