import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from 'react-router-dom';

type Breadcrumb = {
  title: string;
  to: string;
};

type Props = {
  title: string;
  subtitle?: string | React.ReactNode;
  buttons?: React.ReactNode;
  breadcrumbs?: Breadcrumb[];
  noBack?: boolean;
  back?: string;
};

const Header: React.FC<Props> = ({
  title,
  subtitle,
  buttons,
  breadcrumbs,
  noBack = false,
  back,
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow-sm">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
        <div className="lg:flex lg:items-center lg:justify-between w-full">
          <div className="flex-1 min-w-0">
            {!noBack && (
              <nav className="sm:hidden" aria-label="Back">
                <button
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={() => {
                    back ? navigate(back) : navigate(-1);
                  }}
                >
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </button>
              </nav>
            )}
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                {breadcrumbs?.map((breadcrumb, i) => (
                  <li>
                    <div className="flex items-center">
                      {!!i && (
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      <Link
                        to={breadcrumb.to}
                        className={[
                          'text-sm font-medium text-gray-500 hover:text-gray-700',
                          i ? 'ml-4' : '',
                        ].join(' ')}
                      >
                        {breadcrumb.title}
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
            <h2 className="mt-4 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">
              {title}
            </h2>
            <div className="mt-1 flex flex-col text-sm text-gray-500">
              {subtitle}
            </div>
          </div>
          <div className="mt-5 lg:mt-0 lg:ml-4 flex flex-row gap-4">
            {buttons}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
