import { Dialog } from '@headlessui/react';
import { CheckIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import Modal from '../common/Modal';
import { hcVerbiage } from '../common/verbiage';
import { MeContext } from '../contexts/me.context';
import userService from '../services/user.service';
import mildPain from './images/mildPain.png';
import moderatePain from './images/moderatePain.png';
import noPain from './images/noPain.png';
import severePain from './images/severePain.png';
import verySeverePain from './images/verySeverePain.png';
import worstPain from './images/worstPain.png';

export type PainTypes =
  | 'noPain'
  | 'mild'
  | 'moderate'
  | 'severe'
  | 'verySevere'
  | 'worst';

const painIcons: {
  value: PainTypes;
  icon: string;
  level: number;
}[] = [
  { value: 'noPain', icon: noPain, level: 0 },
  { value: 'mild', icon: mildPain, level: 2 },
  { value: 'moderate', icon: moderatePain, level: 4 },
  { value: 'severe', icon: severePain, level: 6 },
  { value: 'verySevere', icon: verySeverePain, level: 8 },
  { value: 'worst', icon: worstPain, level: 10 },
];

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
};

const PainLevelSurvey: React.FC<Props> = ({ open, setOpen }) => {
  const { me } = useContext(MeContext);
  const [selectedPainLevel, setSelectedPain] = useState<number>();
  const [completed, setCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSelectedPain(undefined);
      setCompleted(false);
      setSubmitting(false);
    }, 200);
  }, [open]);

  const submitPainLevel = async () => {
    if (typeof selectedPainLevel === 'undefined') {
      return;
    }
    try {
      setSubmitting(true);
      await userService.painLevelSurvey(selectedPainLevel);
      setCompleted(true);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={open}
      close={() => {
        setOpen(false);
      }}
    >
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {hcVerbiage.completeExercises[me.language]}
          </Dialog.Title>
          {!completed && (
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {hcVerbiage.pleaseRate[me.language]}
              </p>
            </div>
          )}
        </div>
        <div className="my-6">
          <p className="text-sm leading-5 text-gray-500 dark:text-darkGray-400">
            {completed ? (
              <div className="text-center font-medium text-md text-gray-900 pt-4 pb-6">
                {hcVerbiage.thankYou[me.language]}
              </div>
            ) : (
              <div className="w-full grid grid-cols-6">
                {painIcons.map((icon, i) => (
                  <div className="col-span-1 flex flex-col gap-2 w-12 sm:w-16">
                    <span
                      className={[
                        'cursor-pointer rounded-full border-2',
                        selectedPainLevel === icon.level
                          ? 'border-green-600 bg-green-100'
                          : 'border-gray-50',
                      ].join(' ')}
                      onClick={() => {
                        setSelectedPain(icon.level);
                      }}
                    >
                      <img
                        src={icon.icon}
                        alt={hcVerbiage[icon.value][me.language]}
                      />
                    </span>
                    <span className="text-xs text-center">
                      {hcVerbiage[icon.value][me.language]}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </p>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
          <button
            type="button"
            className={[
              'justify-center inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2',
              completed ? 'col-span-2' : 'col-span-1',
            ].join(' ')}
            onClick={() => {
              setSelectedPain(undefined);
              setOpen(false);
            }}
          >
            {hcVerbiage.close[me.language]}
          </button>

          {!completed && (
            <button
              type="button"
              className={[
                'justify-center inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 hover:opacity-90',
                typeof selectedPainLevel === 'number'
                  ? 'cursor-pointer'
                  : 'bg-gray-300 cursor-not-allowed',
              ].join(' ')}
              style={
                typeof selectedPainLevel === 'number'
                  ? {
                      backgroundColor: me?.clinic?.primaryColor,
                    }
                  : {}
              }
              onClick={submitPainLevel}
              disabled={typeof selectedPainLevel !== 'number' || submitting}
            >
              {hcVerbiage.submit[me.language]}
              <CheckCircleIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PainLevelSurvey;
