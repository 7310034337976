import { classNames } from '@chiroup/core/functions/classNames';
import type { JSONContent } from '@tiptap/react';
import { EditorContent, useEditor } from '@tiptap/react';
import { tiptapExtensions } from './tiptapExtensions';

type Props = {
  value: JSONContent | string;
  className?: string;
  small?: boolean;
};

export const EditorView: React.FC<Props> = ({
  value,
  className = '',
  small,
}) => {
  const editor = useEditor({
    editable: false,
    extensions: tiptapExtensions,
    content: value,
  });

  // useEffect(() => {
  //   editor?.commands.setContent(value);
  // }, [value, editor?.commands]);

  return (
    <div
      className={classNames(
        className,
        'tiptap prose !w-full !max-w-full text-sm',
        small ? 'tiptap-small' : '',
      )}
    >
      <EditorContent editor={editor} />
    </div>
  );
};
