import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

type Props = {
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
  close: () => void;
  className?: string;
  containerClassName?: string;
  initialFocus?: any;
  disableClickOutsideClose?: boolean;
};

const Modal: React.FC<Props> = ({
  isOpen,
  children,
  close,
  className = 'inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6',
  containerClassName = '',
  initialFocus,
  disableClickOutsideClose = false,
}) => {
  const handleClose = () => {
    if (disableClickOutsideClose) {
      return;
    } else {
      close();
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={handleClose}
        initialFocus={initialFocus}
      >
        <div
          className={[
            'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0',
            containerClassName,
          ].join(' ')}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-darkGray-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={className}>{children}</div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
