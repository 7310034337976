import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import App from './app/app';
import './styles/index.css';

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    allowGuestAccess: false,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID as string,
    userPoolClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID as string,
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID as string,
  },
};

Amplify.configure(
  {
    Auth: authConfig,
    API: {
      REST: {
        api: {
          endpoint: import.meta.env.VITE_HEALTHCOM_API as string,
        },
        'api-noauth': {
          endpoint: import.meta.env.VITE_HEALTHCOM_API as string,
        },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async (...args) => {
          // Only do this for api endpoint
          const authToken = (
            await fetchAuthSession()
          ).tokens?.idToken?.toString();
          return {
            Authorization: `Bearer ${authToken}`,
          };
        },
      },
    },
  },
);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  // <StrictMode>
  <BrowserRouter>
    <div className="root-div">
      <App />
    </div>
  </BrowserRouter>,
  // </StrictMode>
);
