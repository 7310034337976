import { Input } from '@chiroup/components';
import { EmailValidator, useForm } from '@chiroup/hooks';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../common/Button';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import userService from '../../services/user.service';
import LoginContainer from './LoginContainer';

const ForgotUsername: React.FC = () => {
  const navigate = useNavigate();
  const { createToast } = useContext(ToastContext);
  const [sent, setSent] = useState(false);
  const [specialError, setSpecialError] = useState<string | null>(null);
  const { value, registerSubmit, onChange, errors, isSubmitting } = useForm<{
    email: string;
  }>(
    { email: '' },
    {
      email: {
        required: {
          message: 'Email is required.',
        },
        pattern: EmailValidator,
      },
    },
  );

  const onSubmit = async () => {
    if (!value.email) {
      return;
    }
    setSpecialError(null);
    return userService.forgotUsername(value.email);
  };

  const onSuccess = () => {
    setSent(true);
  };

  const onFail = (e: any) => {
    if (e?.response?.data?.message === 'No user found with that email.') {
      setSpecialError(
        'Hmm, our system doesn’t recognize your email…Please contact your clinic during business hours for direct help to reset your username & password.',
      );
    } else {
      createToast({
        title: 'Error!',
        description:
          e?.response?.data?.message ||
          `An error occurred while sending username. Please refresh and try
				again.`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
  };

  return (
    <LoginContainer>
      <h2 className="text-3xl font-extrabold text-gray-900">
        Forgot your username?
      </h2>
      <p className="mt-1 text-sm text-gray-600">
        Remember?{' '}
        <Link
          to="/"
          className="font-medium text-primary-500 hover:text-primary-300"
        >
          Sign in
        </Link>
      </p>
      <form
        className="space-y-6 mt-8"
        onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}
        autoComplete="off"
      >
        {sent ? (
          <div>
            <p className="mt-1 text-sm text-gray-600 mb-6 p-4 bg-gray-100 rounded-md">
              You will receive an email in the next 10 minutes with your
              username. Visit our{' '}
              <a
                href="https://mailchi.mp/225e5752148d/faq-healthcomio"
                target="_blank"
                rel="noreferrer"
                className="font-medium hover:text-gray-600"
              >
                FAQ page
              </a>{' '}
              for additional support.
            </p>
          </div>
        ) : (
          <Input
            label="Email"
            name="email"
            onChange={onChange('email')}
            value={value.email}
            errors={errors.fieldErrors?.email}
          />
        )}
        {!!specialError && (
          <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">{specialError}</p>
              </div>
            </div>
          </div>
        )}
        {sent ? (
          <Button
            text="Back to login"
            onClick={() => {
              navigate('/');
            }}
            fullWidth
          />
        ) : (
          <Button
            type="submit"
            text="Send username"
            loading={isSubmitting}
            fullWidth
          />
        )}
      </form>
    </LoginContainer>
  );
};

export default ForgotUsername;
