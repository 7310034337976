import { Popover } from '@headlessui/react';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../contexts/me.context';
import { hcVerbiage } from './verbiage';

const NavigationMobile = () => {
  const { me } = useContext(MeContext);

  const navigation = useMemo(() => {
    return [
      { name: hcVerbiage.exercises[me.language], to: '/' },
      { name: hcVerbiage.conditions[me.language], to: '/conditions' },
      { name: hcVerbiage.advice[me.language], to: '/advice' },
      { name: hcVerbiage.myClinic[me.language], to: '/clinic' },
    ];
  }, [me.language]);

  return (
    <div className="mt-3 px-2 space-y-1">
      {navigation.map((item) => (
        <Popover.Button
          as={Link}
          key={item.name}
          to={item.to}
          className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
        >
          {item.name}
        </Popover.Button>
      ))}
    </div>
  );
};

export default NavigationMobile;
