import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';
import { useContext, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../common/Header';
import ResponsivePlayer from '../common/ResponsivePlayer';
import { hcVerbiage } from '../common/verbiage';
import { MeContext } from '../contexts/me.context';
import { InfoItemHtml } from '@chiroup/components';

const Condition = () => {
  const { me } = useContext(MeContext);
  const { conditionId } = useParams();

  const nextPrevIds = useMemo(() => {
    const currentIndex = me.conditions?.findIndex(
      (condition) => condition.ID === Number(conditionId),
    );
    const prevIndex = me.conditions[currentIndex - 1] ? currentIndex - 1 : null;
    const nextIndex = me.conditions[currentIndex + 1] ? currentIndex + 1 : null;

    const nextId = me.conditions?.[nextIndex ?? -1]?.ID || null;
    const prevId = me.conditions?.[prevIndex ?? -1]?.ID || null;

    return {
      nextId,
      prevId,
    };
  }, [conditionId, me]);

  const condition = useMemo(() => {
    return me.conditions?.find(
      (condition) => condition.ID === Number(conditionId),
    );
  }, [conditionId, me]);

  return (
    <div>
      <Header
        title={condition?.commonName || ''}
        subtitle={
          condition?.name && condition?.name !== condition?.commonName
            ? `${hcVerbiage.clinicalName[me.language]}: ${condition?.name}`
            : ``
        }
        back="/conditions"
        breadcrumbs={[
          {
            title: hcVerbiage.conditions[me.language],
            to: '/conditions',
          },
          {
            title: condition?.name || '',
            to: `/conditions/${conditionId}`,
          },
        ]}
        buttons={
          <>
            {!!nextPrevIds.prevId && (
              <Link to={`/conditions/${nextPrevIds.prevId}`}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <ArrowLeftCircleIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                  {hcVerbiage.previous[me.language]}
                </button>
              </Link>
            )}
            {!!nextPrevIds.nextId && (
              <Link to={`/conditions/${nextPrevIds.nextId}`}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  style={{
                    backgroundColor: me?.clinic?.primaryColor,
                  }}
                >
                  {hcVerbiage.next[me.language]}
                  <ArrowRightCircleIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </Link>
            )}
          </>
        }
      />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
        <div className="flex flex-col w-full">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6 flex flex-col gap-10">
              {!!condition?.patientVideo && (
                <ResponsivePlayer url={condition.patientVideo} />
              )}
              {!!condition?.descr && (
                <InfoItemHtml
                  className="text-gray-500 normal-styling"
                  value={condition.descr}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Condition;
