import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { PatientInvoice } from './PatientInvoice.type';
import {
  PatientTransaction,
  TransactionItemSubtypeEnum,
  TransactionPatientSummaryType,
} from './PatientTransaction.type';
import { IntegrationTypeEnum } from './Integration.type';
import { PayorLineItemType } from './Payor.type';
import {
  PatientPrimaryInsured,
  PrimaryInsuredTypes,
} from './PatientInsurance.type';
import {
  STRING_STRING_HASH,
  SELECT_OPTION_TYPE,
  STRING_ANY_HASH,
  BusinessRuleItemType,
  NUMBER_STRING_HASH,
  STRING_NUMBER_HASH,
} from '../constants/globals';
import { ChiroUpJSON } from '../functions/ChiroUpJSON';

dayjs.extend(dayOfYear);

export enum ClaimDelayReasonEnum {
  EligibilityUnknown = 1,
  Litigation = 2,
  AuthorizationDelays = 3,
  CertifyingProvider = 4,
  SupplyingBillingForms = 5,
  CustomApplianceDelivery = 6,
  ThirdPartyProcessing = 7,
  EligibilityDetermination = 8,
  OriginalClaimRejectedOrDenied = 9,
  AdministrationPriorApprovalProcess = 10,
  Other = 11,
  NaturalDisaster = 15,
}

export const ClaimDelayReasonText: { [key: number]: string } = {
  1: 'Proof of Eligibility Unknown',
  2: 'Litigation',
  3: 'Authorization Delays',
  4: 'Delay in Certifying Provider',
  5: 'Delay in Supplying Billing Forms',
  6: 'Delay in Delivery of Custom Appliances',
  7: 'Third Party Processing Delay',
  8: 'Delay in Eligibility Determination',
  9: 'Original Claim Rejected or Denied Due to Reason Unrelated to the Billing Limitation Rules',
  10: 'Administration Delay in the Prior Approval Process',
  11: 'Other',
  15: 'Natural Disaster',
};

export const ClaimDelayReasonOptions = Object.keys(ClaimDelayReasonEnum)
  .filter((key) => /^\d+$/.test(key))
  .map((key) => ({
    text: ClaimDelayReasonText[Number(key)] || `-- woops ${key} --`,
    value: key,
  }))
  .sort((a, b) => (a.text > b.text ? 1 : -1));

export enum InvoiceGenerationEnum {
  Original = 'Original',
  Replacement = 'Replacement',
  Void = 'Void',
}

/**
 * Data structure to validate inputs.
 */
export const InvoiceGenerationEdiXref: { [key: string]: string } = {
  [InvoiceGenerationEnum.Original]: '1',
  [InvoiceGenerationEnum.Replacement]: '7',
  [InvoiceGenerationEnum.Void]: '8',
  '1': InvoiceGenerationEnum.Original,
  '7': InvoiceGenerationEnum.Replacement,
  '8': InvoiceGenerationEnum.Void,
};

/**
 * At this point, this is _only_ to select what we'll send to the EDI
 * for the generation. So, the values are the EDI field values and not
 * the strings from the enum.
 */
export const InvoiceGenerationOptions = Object.keys(InvoiceGenerationEnum).map(
  (key) => ({
    value: InvoiceGenerationEdiXref[key],
    text: key,
  }),
);

export enum InvoiceStatusEnum {
  // Hold - Claim is on hold and will not be processed until the hold is
  // removed. Stops the claim from being processed without manual intervention.
  Hold = 'Hold',
  // Waiting on Other - Claim can not be generated until the claim ahead
  // of it in the priority order has been denied or paid. This can not be
  // set by a user. The system auto-sets this for downstream payors.
  Waiting = 'Waiting on Other',
  // Pending - Information that is required for claim generation is missing.
  Pending = 'Pending',
  // Unsubmitted - Claim is ready to be downloaded or submitted (this an
  // auto-status, users can not change a claim status to this).
  Unsubmitted = 'Unsubmitted',
  // Submitted -Claim has been submitted to the Payor for reimbursement.
  Submitted = 'Submitted',
  // Received - this means that the Payor has acknowledged that they have
  // received the claim.
  Received = 'Received',
  // Rejected - Claim has not been processed by the Payor due to errors or
  // invalid information discovered during the initial validation process.
  Rejected = 'Rejected',
  // Denied - Claim has undergone review and the Payor determined the claim
  // to be ineligible for reimbursement.
  Denied = 'Denied',
  // Partially Paid - Claim has been partially paid and the clinic is
  // awaiting another claim response.
  Partial = 'Partially Paid',
  // Paid - Claim has been processed and payment from Payor has been received.
  Paid = 'Paid',
  // This is a status just for patient invoices that denotes submitted but not paid
  Unpaid = 'Unpaid',
}

export const PostSubmittedInvoiceStatuses = [
  InvoiceStatusEnum.Received,
  InvoiceStatusEnum.Rejected,
  InvoiceStatusEnum.Denied,
  InvoiceStatusEnum.Partial,
  InvoiceStatusEnum.Paid,
  InvoiceStatusEnum.Unpaid,
];

export const isInvoiceReadonly = (status: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.Hold:
    case InvoiceStatusEnum.Waiting:
    case InvoiceStatusEnum.Pending:
    case InvoiceStatusEnum.Unsubmitted:
    case InvoiceStatusEnum.Rejected:
      return false;
    default:
      return true;
  }
};

export const isStatusWaitingSupported = (status: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.Pending:
    case InvoiceStatusEnum.Unsubmitted:
      return true;
    default:
      return false;
  }
};

export const isNotInvoiceReadonly = (status: InvoiceStatusEnum) => {
  return !isInvoiceReadonly(status);
};

export const InvoiceStatusTips: STRING_STRING_HASH = {
  [InvoiceStatusEnum.Hold]:
    'All claim processing is disabled until the hold is removed.',
  [InvoiceStatusEnum.Waiting]: [
    'Claim can not be generated until the claim ahead',
    'of it in the priority order has been denied or paid.',
  ].join(' '),
  [InvoiceStatusEnum.Pending]:
    'Information that is required for claim generation is missing.',
  [InvoiceStatusEnum.Unsubmitted]: 'Claim is ready to be submitted.',
  [InvoiceStatusEnum.Submitted]:
    'Claim has been submitted to the Payor for reimbursement.',
  [InvoiceStatusEnum.Received]:
    'Indicates that the payor has acknowledged receipt of the claim.',
  [InvoiceStatusEnum.Rejected]: [
    'Payor has not processed the claim due to errors or',
    'invalid information discovered during the validation process.',
  ].join(' '),
  // Denied - Claim has undergone review and the Payor determined the claim
  // to be ineligible for reimbursement.
  [InvoiceStatusEnum.Denied]: [
    'Payor has reviewed the claim and determined it',
    'to be ineligible for reimbursement',
  ].join(' '),
  [InvoiceStatusEnum.Partial]: [
    'Claim has been partially paid and the clinic is',
    'awaiting another claim response.',
  ].join(' '),
  [InvoiceStatusEnum.Paid]:
    'Claim has been processed and payment has been received.',
};

/**
 * The trick here is that the indices of the values and the
 * keys are in the same order so the options can be created
 * correctly with the ones a user can't select filtered out.
 */
let vals: any[] = Object.values(InvoiceStatusEnum).filter(
  (v) => v !== 'Unsubmitted' && v !== 'Waiting on Other',
);
export const InvoiceStatusOptions: SELECT_OPTION_TYPE[] = Object.keys(
  InvoiceStatusEnum,
)
  .filter((key) => key !== 'Unsubmitted' && key !== 'Waiting')
  .map((key, idx) => ({ text: vals[idx], value: key }));

export const AllInvoiceStatusDisplay: STRING_STRING_HASH = {};
vals = Object.values(InvoiceStatusEnum);
export const AllInvoiceStatusOptions = Object.keys(InvoiceStatusEnum).map(
  (key, idx) => {
    AllInvoiceStatusDisplay[vals[idx]] = key;
    return { text: vals[idx], value: key };
  },
);

export const AllowedInvoiceStatusTransitions: STRING_ANY_HASH = {
  [InvoiceStatusEnum.Submitted]: {
    [InvoiceStatusEnum.Received]: true,
    [InvoiceStatusEnum.Rejected]: true,
    [InvoiceStatusEnum.Denied]: true,
    [InvoiceStatusEnum.Unpaid]: true,
  },
  [InvoiceStatusEnum.Received]: {
    [InvoiceStatusEnum.Rejected]: true,
    [InvoiceStatusEnum.Denied]: true,
    [InvoiceStatusEnum.Unpaid]: true,
    [InvoiceStatusEnum.Paid]: true,
  },
  [InvoiceStatusEnum.Rejected]: {
    [InvoiceStatusEnum.Unpaid]: true,
  },
  [InvoiceStatusEnum.Denied]: {
    [InvoiceStatusEnum.Rejected]: true,
    [InvoiceStatusEnum.Unpaid]: true,
  },
  [InvoiceStatusEnum.Partial]: {},
  [InvoiceStatusEnum.Paid]: {},
  [InvoiceStatusEnum.Unpaid]: {},
  [InvoiceStatusEnum.Pending]: {
    [InvoiceStatusEnum.Hold]: true,
  },
  [InvoiceStatusEnum.Unsubmitted]: {
    [InvoiceStatusEnum.Hold]: true,
  },
  [InvoiceStatusEnum.Waiting]: {},
  [InvoiceStatusEnum.Hold]: {
    [InvoiceStatusEnum.Pending]: true,
  },
};

/**
 * The function could be shorter, but I like the readability.
 *
 * @param status
 * @returns
 */
export const canSubmitNewEdi = (status: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.Waiting:
      return false;
    case InvoiceStatusEnum.Pending:
      return true;
    case InvoiceStatusEnum.Unsubmitted:
      return true;
    case InvoiceStatusEnum.Submitted:
      return false;
    case InvoiceStatusEnum.Received:
      return false;
    case InvoiceStatusEnum.Rejected:
      return false;
    case InvoiceStatusEnum.Denied:
      return false;
    case InvoiceStatusEnum.Partial:
      return false;
    case InvoiceStatusEnum.Paid:
      return false;
    default:
      return true;
  }
};

export type InsuranceInvoiceData = {
  latest: InvoiceDataHistoryType;
  history: InvoiceDataHistoryType[];
};

export type InvoiceDataHistoryType = {
  billedAmount: number;
  balance: number;
  status: string | InvoiceStatusEnum;
  timestamp: Date;
  segments: string[];
};

export type InvoiceInsert = {
  clinicId: number;
  billingKey: string;
  billedAmount?: number;
  balance?: number;
  payorId?: number;
  insuranceId?: string;
  patientId: string;
  status: string;
  invoiceData?: any;
  createdAt?: number;
  issuedDate?: number;
  writeOffTotal?: number;
};

export type InvoiceEdiMetaType = {
  delayReason?: string;
  generation?: string;
};

export type Invoice = InvoiceInsert & {
  id: number;
  invoiceData?: any | PatientInvoice;
  number: string;
  controlNumber?: number;
  //The claim control number is provided in the claim response from the payor...
  claimControlNumber?: string;
  statusAt?: number;
  deleted?: boolean;
  statusData?: InvoiceStatusData | null;
  legalName?: string;
  uiLegalName?: string;
  priority?: number;
  interchangeControlNumber?: number;
  exchangeId?: string;
  currentControlNumber?: string;
  delayReason?: string;
  generation?: string;
  issuedDate?: number;
  dispatched?: boolean; //printed or emailed invoice
  tz?: string; // Timezone: Pulled from ClinicLocation, not in the Invoice table.
  isBillingStarted?: boolean; // Calculated from PayorLineItem.
  superBill?: boolean;
  billingCodes?: string[];
  // isBillingStarted is NOT ALWAYS FILLED IN! Make sure the query
  // you're using is pulling it if you need it!!!
};

/**
 * Integration invoices have a lot of extra details than normal invoices.
 * To retrieve them, joins to multiple tables are necessary so use the
 * normal invoice most of the time. Use this type of invoice when specific
 * details about whether an EDI document needs to be created. The extra
 * fields are needed for running the business rules: UI and back end.
 *
 * Most of the time EDI creation is not an issue when dealing with invoices
 * so the extra table joins are not needed.
 */
export type IntegrationInvoice = Invoice & {
  legalName: string;
  patient: TransactionPatientSummaryType;
  providerDisplayName: string;
  interchangeControlNumber?: number;
  transactionDate?: number;
  billingProfileId?: number;
  billingProfileType?: string;
  billingProfileName?: string;
  billingProfile?: IntegrationInvoiceBillingProfile;
  provider?: IntegrationInvoiceProvider;
  primaryInsured?: PatientPrimaryInsured & {
    memberId: string;
    type: PrimaryInsuredTypes;
  };
  pos?: string;
  integrationId?: number;
  integrationType?: IntegrationTypeEnum;
  payorLineItems?: PayorLineItemType[];
  electronicBilling: boolean;
  integrationActive?: boolean;
};

export type IntegrationInvoiceBillingProfile = {
  id: number;
  name: string;
  type: string;
  npi: string;
};

export type IntegrationInvoiceProvider = {
  name: string;
  npi: string;
};

export type IntegrationInvoiceProcessedType = {
  [key: number]: {
    issues: BusinessRuleItemType[];
    notes: BusinessRuleItemType[];
    success: boolean;
  };
};

export type IntegrationInvoicePayorSummary = {
  payorId: number;
  billedAmount: number;
  balance: number;
};

export type InvoiceDetailType = Invoice & {
  legalName: string;
  priority: string;
};

/**
 * A trivial little helper to generate a baseId for an invoice. The
 * 'id' and 'baseId' are added together to get the invoiceId that is
 * sent to the payor in EDI.
 *
 * This _may_ be unnecessary, but it seemed weird to have invoice nos
 * of 1, 2, 3,... So, we go off the base of the current year minus 2020
 * followed by the day of the year and then the id of the invoice. This
 * should always be unique but harder to guess across clinics.
 *
 * @returns
 */
export const invoiceBaseId = () => {
  const yearPart = Number(dayjs().format('YYYY')) - 2020,
    dayPart = `000${dayjs().dayOfYear()}`.slice(-3);
  return Number(`${yearPart}${dayPart}`);
};

/**
 * Yes, I know, this goes to ELEVEN!!!  Wonder if the EDI
 * boffins did that on purpose!
 */
export enum PayorPriorityEnum {
  Primary = 'P',
  Secondary = 'S',
  Tertiary = 'T',
  Fourth = 'A',
  Fifth = 'B',
  Sixth = 'C',
  Seventh = 'D',
  Eighth = 'E',
  Nineth = 'F',
  Tenth = 'G',
  Eleventh = 'H',
}

export const PayorPriorityDisplay: NUMBER_STRING_HASH = {
  [-1]: '-na-',
  0: '-na-',
  1: 'Primary',
  2: 'Secondary',
  3: 'Tertiary',
  4: 'Fourth',
  5: 'Fifth',
  6: 'Sixth',
  7: 'Seventh',
  8: 'Eighth',
  9: 'Nineth',
  10: 'Tenth',
  11: 'Eleventh',
};

vals = Object.values(PayorPriorityEnum);
export const PayorPriorityOptions = Object.keys(PayorPriorityEnum).map(
  (key, idx) => ({ value: vals[idx], text: key }),
);
export const PayorPriorityText: STRING_STRING_HASH =
  PayorPriorityOptions.reduce((a, c) => {
    a[c.value] = c.text;
    return a;
  }, {} as STRING_STRING_HASH);

export const PayorPriorityIdText: NUMBER_STRING_HASH =
  PayorPriorityOptions.reduce((a, c, i) => {
    a[i + 1] = c.text;
    return a;
  }, {} as NUMBER_STRING_HASH);

export const PayorPriorityNumber: STRING_NUMBER_HASH =
  PayorPriorityOptions.reduce((a, c, idx) => {
    a[c.value] = idx + 1;
    return a;
  }, {} as STRING_NUMBER_HASH);

export const PayorPriorityCode: NUMBER_STRING_HASH =
  PayorPriorityOptions.reduce((a, c, idx) => {
    a[idx + 1] = c.value;
    return a;
  }, {} as NUMBER_STRING_HASH);

type respType = {
  serviceTotal: number;
  involvesSpinalManipulation: boolean;
  patientAmountPaid: number;
};

/**
 * This returns details about the transaction/purchase that are needed for
 * an invoice and for EDI.
 *
 *
 * @param param0
 * @returns
 */
export const invoiceTransactionMetaData = ({
  transaction,
}: {
  transaction: PatientTransaction;
}): respType => {
  let resp: respType = {
    serviceTotal: 0,
    involvesSpinalManipulation: false,
    patientAmountPaid: 0,
  };

  if (!transaction || !transaction.items || !transaction.items.length) {
    return resp;
  }
  resp = transaction.items.reduce(
    (acc, item) => {
      if (item.subtype === TransactionItemSubtypeEnum.Service) {
        acc.serviceTotal += Number(item.amount) * Number(item.units);
        if (
          item.code === '98940' ||
          item.code === '98941' ||
          item.code === '98942' ||
          item.code === '98940S'
        ) {
          acc.involvesSpinalManipulation = true;
        }
      } else if (item.subtype === TransactionItemSubtypeEnum.PatientPayment) {
        acc.patientAmountPaid += Number(item.amount);
      } else if (item.subtype === TransactionItemSubtypeEnum.PatientCredit) {
        acc.patientAmountPaid += Number(item.amount);
      } else if (item.subtype === TransactionItemSubtypeEnum.PatientRefund) {
        acc.patientAmountPaid -= Number(item.amount);
      }
      return acc;
    },
    resp, // Reduce initial value from default response.
  );
  return resp;
};

type _ediItemFields = {
  qualifier: string;
  code: string;
  name: string;
};

export const EdiInterchangeTypes: _ediItemFields[] = [
  { qualifier: '01', code: 'duns', name: 'Duns' },
  { qualifier: '14', code: 'dunsps', name: 'Duns Plus Suffix' },
  { qualifier: '20', code: 'hin', name: 'Health Industry Number' },
  {
    qualifier: '27',
    code: 'cin',
    name: 'Carrier Identification Number assigned by HCFA',
  },
  {
    qualifier: '28',
    code: 'fiin',
    name: 'Fiscal Intermediary Identifcation Number as assigned by HCFA',
  },
  {
    qualifier: '29',
    code: 'mpsin',
    name: 'Medicate Provider and Supplier Identification Number as assigned by HCFA',
  },
  { qualifier: '30', code: 'taxId', name: 'Tax ID Number' },
  { qualifier: '33', code: 'naicCode', name: 'NAIC Code' },
  { qualifier: 'ZZ', code: 'mutual', name: 'Mututally Defined' },
];

export const EdiInterchangeTypeByCode = EdiInterchangeTypes.reduce(
  (a, c) => {
    a[c.code] = c;
    return a;
  },
  {} as { [key: string]: _ediItemFields },
);

export type CreateEdiPostContextType = {
  billingKey?: string;
  invoiceIds?: number[];
  invoice?: Invoice;
  payorPriorityCode?: string;
  meta?: {
    [key: number]: {
      delayReason?: string;
      generation?: string;
      claimControlNumber?: string;
    };
  }; // Additional details to be sent to the EDI.
  targetStatus?: string;
  debug?: boolean;
  trace?: boolean;
  update?: boolean;
  allOrNone?: boolean;
  batch?: boolean;
  mock?: {
    enable: boolean;
    values: { [key: string]: any };
  };
  testUpload?: boolean;
};

export type CreateEdiPostType = {
  context: CreateEdiPostContextType;
};

/**
 * This is an example payload to build off of for testing.
 */
export const CreateEdiPostDefaults: CreateEdiPostType = {
  context: {
    billingKey: '7506cf56-b3fc-414a-925f-ee0ab908a67d',
    payorPriorityCode: 'P',
    targetStatus: '',
    debug: false,
    trace: false,
    update: false,
    meta: {},
    allOrNone: true,
    mock: {
      enable: true,
      values: {
        appointmentPolicies: {
          active: false,
          value: ChiroUpJSON.pretty([
            {
              disciplineID: 15,
            },
            {
              disciplineID: 13,
            },
          ]),
        },
        daysSinceService: {
          active: false,
          value: 135,
        },
        encounterClinicianId: {
          active: false,
          value: '997feee4-6101-4359-9a2c-57a51247b3f8',
        },
        initialPayorIds: {
          active: true,
          value: ChiroUpJSON.pretty([
            {
              payorID: 8,
              insuranceID: '',
              billingRef: '',
              code: '',
            },
            {
              payorID: 4,
              insuranceID: '',
              billingRef: '',
              code: '',
            },
          ]),
        },
        involvesSpinalManipulation: {
          active: false,
          value: false,
        },
        isPatientSubscriber: {
          active: false,
          value: false,
        },
        patientAmountPaid: {
          active: false,
          value: 125.5,
        },
        transactionLocked: {
          active: true,
          value: true,
        },
        payorPriority: {
          active: false,
          value: PayorPriorityEnum.Primary,
        },
        addTransactionInsurances: {
          active: true,
          value: ChiroUpJSON.pretty([
            {
              ID: 92,
              appointmentID: '7506cf56-b3fc-414a-925f-ee0ab908a67d',
              clinicID: 1,
              patientID: 'b11f2534-e6c4-4658-bab9-525517714583',
              insuranceID: 'a23a11a7-c8b5-4ef2-b9d5-cac76888fef6',
              billingPriority: 2,
              deductible: '0.00',
              insuranceName: 'BlueCrossBlueShield',
              payorID: 4,
            },
          ]),
        },
        serviceTotal: {
          active: true,
          value: 125.5,
        },
      },
    },
  },
};

export type InvoiceStatusData = {
  items: InvoiceStatusDataItemType[];
};

export type InvoiceStatusDataItemType = {
  ts: string;
  userId: string;
  previous: string;
  target: string;
  final: string;
  issues: BusinessRuleItemType[];
  notes: BusinessRuleItemType[];
  claimControlNumber?: string;
  exchangeId?: string;
  interchangeControlNumber?: string;
};

export const initialInvoiceStatusData = ({
  userId,
  invoice,
}: {
  userId: string;
  invoice?: Invoice;
}) => {
  return {
    items: [
      {
        ts: Date.now().toString(),
        userId,
        previous: '-none-',
        target: InvoiceStatusEnum.Pending,
        final: invoice?.status ?? InvoiceStatusEnum.Pending,
        issues: [],
      },
    ],
  };
};

export class InvoiceIssueObject {
  public issue: BusinessRuleItemType;
  public text: string;
  public resolved: boolean;

  constructor(issue: BusinessRuleItemType, resolved: boolean = false) {
    if (typeof issue === 'string') issue = { text: issue };
    this.issue = issue;
    this.text = issue.text;
    this.resolved = resolved;
  }
}

/**
 * This adds a new item to the invoice status data. It will create an
 * initial empty one if it does not exist. Otherwise, it just addes to
 * the list.
 *
 * Note that the MOST RECENT IS AT THE TOP. That is items[0] is always
 * the latest.
 *
 * @param param0
 * @returns
 */
export const addInvoiceStatusDataItem = ({
  invoice,
  userId,
  targetStatus,
  revertStatus,
  issues = [],
  notes = [],
}: {
  invoice?: Invoice | null;
  userId: string;
  targetStatus: string;
  revertStatus?: string;
  issues?: BusinessRuleItemType[];
  notes?: BusinessRuleItemType[];
}) => {
  if (!invoice) throw new Error('Invoice is required.');
  if (!userId) throw new Error('userId is required.');

  let current = null;

  current = invoice.statusData
    ? invoice.statusData
    : initialInvoiceStatusData({ userId, invoice });
  if (typeof current === 'string') {
    current = JSON.parse(current) as InvoiceStatusData;
  }
  revertStatus = revertStatus || invoice.status;
  if (
    issues &&
    issues.length > 0 &&
    targetStatus === InvoiceStatusEnum.Received
  ) {
    revertStatus = InvoiceStatusEnum.Rejected;
  }

  /**
   * Rule: It makes no sense for a primary to be "waiting" on
   * anything if there are no issues. So, it is at least pending.
   */
  if (invoice.status === InvoiceStatusEnum.Waiting && invoice.priority === 1) {
    revertStatus = InvoiceStatusEnum.Pending;
  }

  const item = {
    ts: Date.now().toString(),
    userId,
    previous: invoice.status,
    target: targetStatus,
    claimControlNumber: invoice.claimControlNumber,
    exchangeId: invoice.exchangeId,
    interchangeControlNumber: invoice.interchangeControlNumber,
    delayReason: invoice.delayReason,
    generation: invoice.generation,
    final:
      issues && Array.isArray(issues) && issues.length === 0
        ? targetStatus
        : revertStatus,
    issues,
    notes,
  };
  const clone = ChiroUpJSON.clone(current, { items: [] });
  clone.items = clone.items || [];
  clone.items.unshift(item);

  return clone as InvoiceStatusData;
};
