import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Fragment, useContext } from 'react';
import { MeContext } from '../contexts/me.context';
import userService from '../services/user.service';
import { hcVerbiage } from './verbiage';
import { signOut } from '@aws-amplify/auth';

const UserNavigationMobile = () => {
  const { me, updateMe } = useContext(MeContext);

  const logout = async () => {
    await signOut();
  };

  const patientSurveysChange = async () => {
    const newValue = me.unsubscribe ? 0 : 1;
    await userService.surveyEmails(!!newValue);
    updateMe('unsubscribe', newValue);
  };

  return (
    <div className="hidden lg:ml-4 lg:flex lg:items-center">
      <Menu as="div" className="ml-4 relative flex-shrink-0">
        <div>
          <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2">
            <span className="sr-only">
              {hcVerbiage.openUserMenu[me.language]}
            </span>
            <UserCircleIcon className="h-8 w-8 text-gray-600" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <a
                href="https://chiroup-assets.s3.amazonaws.com/clinics/0/form/files/569e89f7-fead-4393-8c0c-372bb3fe1cb9.pdf"
                target="_blank"
                rel="noreferrer"
                className="block px-4 py-2 text-sm text-gray-600"
              >
                {hcVerbiage.termsOfUse[me.language]}
              </a>
            </Menu.Item>
            <Menu.Item>
              <span
                onClick={patientSurveysChange}
                className="block px-4 py-2 text-sm text-gray-600 cursor-pointer"
              >
                {
                  hcVerbiage[me.unsubscribe ? 'resubscribe' : 'unsubscribe'][
                    me.language
                  ]
                }
              </span>
            </Menu.Item>
            <Menu.Item>
              <span
                onClick={logout}
                className="block px-4 py-2 text-sm text-gray-600 cursor-pointer"
              >
                {hcVerbiage.signOut[me.language]}
              </span>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default UserNavigationMobile;
