const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatPhone = (phone?: string, noIntl?: boolean) => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const usMatch = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (usMatch) {
    const intlCode = usMatch[1] && !noIntl ? '+1 ' : '';
    return [intlCode, '(', usMatch[2], ') ', usMatch[3], '-', usMatch[4]].join(
      '',
    );
  }
  return phone;
};

export const formatCurrency = (val?: number) => {
  return formatter.format(val || 0);
};
