export const ScheduleDefaults = {
  Sunday: [],
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
};

export const ScheduleClinicSettings = {
  cancellationOptions: [
    { value: 0, text: 'No Restriction' },
    { value: 5, text: '5 minutes' },
    { value: 10, text: '10 minutes' },
    { value: 15, text: '15 minutes' },
    { value: 30, text: '30 minutes' },
    { value: 45, text: '45 minutes' },
    { value: 60, text: '1 hour' },
    { value: 60 * 2, text: '2 hours' },
    { value: 60 * 3, text: '3 hours' },
    { value: 60 * 4, text: '4 hours' },
    { value: 60 * 5, text: '5 hours' },
    { value: 60 * 6, text: '6 hours' },
    { value: 60 * 7, text: '7 hours' },
    { value: 60 * 8, text: '8 hours' },
    { value: 60 * 9, text: '9 hours' },
    { value: 60 * 10, text: '10 hours' },
    { value: 60 * 11, text: '11 hours' },
    { value: 60 * 12, text: '12 hours' },
    { value: 60 * 24, text: '24 hours' },
    { value: 60 * 36, text: '36 hours' },
    { value: 60 * 48, text: '48 hours' },
    { value: 60 * 24 * 3, text: '3 days' },
    { value: 60 * 24 * 4, text: '4 days' },
    { value: 60 * 24 * 5, text: '5 days' },
    { value: 60 * 24 * 6, text: '6 days' },
    { value: 60 * 24 * 7, text: '7 days' },
  ],
  defaultDurationOptions: [
    { text: '15 minutes', value: 15 },
    { text: '30 minutes', value: 30 },
    { text: '45 minutes', value: 45 },
    { text: '1 hour', value: 60 },
    { text: '1 hour, 15 minutes', value: 75 },
    { text: '1 hour, 30 minutes', value: 90 },
    { text: '1 hour, 45 minutes', value: 105 },
    { text: '2 hours', value: 120 },
  ],
};
