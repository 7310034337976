import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../contexts/me.context';
import icon from '../../assets/icon.png';

const Logo = () => {
  const { me } = useContext(MeContext);

  const imageUrl = useMemo(() => {
    const isUrl = me?.clinic?.logo?.startsWith('http');
    return isUrl
      ? me?.clinic?.logo
      : `${import.meta.env.VITE_S3_ASSETS_URL}/${me?.clinic?.logo}`;
  }, [me?.clinic?.logo]);

  return (
    <Link to="/">
      <img
        className={['h-8 w-auto', me?.clinic?.logo ? 'h-8' : 'h-12'].join(' ')}
        src={imageUrl || icon}
        alt={me?.clinic?.name || 'HealthCom'}
      />
    </Link>
  );
};

export default Logo;
