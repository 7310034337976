import React from 'react';
import Loading from './Loading';

const LoadingPage = () => {
  return (
    <div className="flex justify-center py-8">
      <Loading color="text-gray-400" size={10} className="-ml-1 mr-3" />
    </div>
  );
};

export default LoadingPage;
