import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import BlockQuote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import History from '@tiptap/extension-history';

const tiptapExtensionsBase = [
  BlockQuote,
  BulletList,
  CodeBlock,
  Document,
  HardBreak,
  Heading,
  HorizontalRule,
  ListItem,
  OrderedList,
  Paragraph,
  Text,
  Bold,
  Code,
  Italic,
  Strike,
  Dropcursor,
  Gapcursor,
  History,
  Placeholder.configure({
    placeholder: 'Enter a description...',
  }),
  Link.configure({
    openOnClick: false,
    validate: (href) => /^https?:\/\//.test(href),
    protocols: ['http', 'https', 'mailto', 'tel'],
  }),
];

export default tiptapExtensionsBase;
