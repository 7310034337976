import React from 'react';
import { icons } from './icons';

type Props = {
  icon: keyof typeof icons;
  className?: string;
  onClick?: (e: Event) => void;
  style?: React.CSSProperties;
};

export const Icon: React.FC<Props> = ({
  icon,
  className = '',
  style = {},
  onClick,
}) => {
  const IconSVG = icons[icon] || icons.lightBulb;
  return React.cloneElement(IconSVG, { className, onClick, style });
};
