import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../contexts/me.context';
import { hcVerbiage } from './verbiage';

const Navigation = () => {
  const { me } = useContext(MeContext);

  const navigation = useMemo(() => {
    return [
      { name: hcVerbiage.exercises[me.language], to: '/' },
      { name: hcVerbiage.conditions[me.language], to: '/conditions' },
      { name: hcVerbiage.advice[me.language], to: '/advice' },
      { name: hcVerbiage.myClinic[me.language], to: '/clinic' },
    ];
  }, [me.language]);

  return (
    <nav
      aria-label="Global"
      className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
    >
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.to}
          className="px-3 py-2 text-gray-900 text-sm font-medium"
        >
          {item.name}
        </Link>
      ))}
    </nav>
  );
};

export default Navigation;
