import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

const currentDay = Number(dayjs().format('D'));

type Props = {
  days: number;
  skipDays: number;
  selectDate: (day: number) => void;
  selectedDate?: Dayjs;
  viewDate: Dayjs;
  pastOnly?: boolean;
  futureOnly?: boolean;
  primaryColor: string;
  dottedDays?: {
    [key: number]: boolean;
  };
};

const DatepickerDays: React.FC<Props> = ({
  days,
  skipDays,
  selectDate,
  selectedDate,
  viewDate,
  pastOnly,
  futureOnly,
  primaryColor,
  dottedDays,
}) => {
  const [thisMonth, setThisMonth] = useState<boolean>();

  useEffect(() => {
    const startOfSelectedDateYear = selectedDate?.startOf('month');
    const startOfViewDateYear = viewDate.startOf('month');
    setThisMonth(
      startOfSelectedDateYear &&
        startOfViewDateYear.isSame(startOfSelectedDateYear),
    );
  }, [selectedDate, viewDate]);

  const selectDay = (day: number) => {
    selectDate(day);
  };

  const currentMonth = useMemo(() => {
    return dayjs().isSame(viewDate, 'month');
  }, [viewDate]);

  const futureMonth = useMemo(() => {
    return dayjs().isBefore(viewDate, 'month');
  }, [viewDate]);

  const priorMonth = useMemo(() => {
    return dayjs().isAfter(viewDate, 'month');
  }, [viewDate]);

  return (
    <>
      {!!skipDays && <div className={`day col-span-${skipDays}`} />}
      {Array.from(Array(days)).map((_, i) => {
        if (
          (currentMonth && pastOnly && i + 1 > currentDay) ||
          (currentMonth && futureOnly && i + 1 < currentDay) ||
          (futureOnly && priorMonth) ||
          (pastOnly && futureMonth)
        ) {
          return (
            <div
              key={i}
              className="col-span-1 text-center rounded-full h-8 w-8 text-gray-400 cursor-not-allowed flex items-center justify-center"
            >
              <span className="align-middle place-self-center">{i + 1}</span>
            </div>
          );
        } else {
          const isDotted = dottedDays?.[i + 1];
          const clickable = !dottedDays || (dottedDays && isDotted);
          return (
            <div
              key={i}
              className={[
                'col-span-1 text-center rounded-full h-8 w-8 flex items-center justify-center relative',
                thisMonth && selectedDate?.date() === i + 1
                  ? 'text-white'
                  : clickable
                    ? 'hover:bg-gray-600 hover:text-gray-50'
                    : '',
                clickable
                  ? 'cursor-pointer'
                  : 'text-gray-400 cursor-not-allowed',
              ].join(' ')}
              style={
                thisMonth && selectedDate?.date() === i + 1
                  ? { backgroundColor: primaryColor }
                  : {}
              }
              onClick={() => {
                if (clickable) {
                  selectDay(i + 1);
                }
              }}
            >
              <span className="align-middle place-self-center">{i + 1}</span>
              {isDotted && (
                <div
                  className="w-1 h-1 rounded-full absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  style={{
                    backgroundColor:
                      thisMonth && selectedDate?.date() === i + 1
                        ? '#ffffff'
                        : primaryColor,
                  }}
                />
              )}
            </div>
          );
        }
      })}
    </>
  );
};

export default DatepickerDays;
