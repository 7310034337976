import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';
import { useContext, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../common/Header';
import ResponsivePlayer from '../common/ResponsivePlayer';
import { hcVerbiage } from '../common/verbiage';
import { MeContext } from '../contexts/me.context';
import { InfoItemHtml } from '@chiroup/components';

const Advice = () => {
  const { me } = useContext(MeContext);
  const { adviceId } = useParams();

  const nextPrevIds = useMemo(() => {
    const currentIndex = me.advice?.findIndex(
      (advice) => advice.ID === Number(adviceId),
    );
    const prevIndex = me.advice[currentIndex - 1] ? currentIndex - 1 : null;
    const nextIndex = me.advice[currentIndex + 1] ? currentIndex + 1 : null;

    const nextId = me.advice?.[nextIndex ?? -1]?.ID || null;
    const prevId = me.advice?.[prevIndex ?? -1]?.ID || null;

    return {
      nextId,
      prevId,
    };
  }, [adviceId, me]);

  const advice = useMemo(() => {
    return me.advice?.find((advice) => advice.ID === Number(adviceId));
  }, [adviceId, me]);

  return (
    <div>
      <Header
        title={advice?.name || ''}
        breadcrumbs={[
          {
            title: hcVerbiage.advice[me.language],
            to: '/advice',
          },
          {
            title: advice?.name || '',
            to: `/advice/${adviceId}`,
          },
        ]}
        back="/advice"
        buttons={
          <>
            {!!nextPrevIds.prevId && (
              <Link to={`/advice/${nextPrevIds.prevId}`}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <ArrowLeftCircleIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                  {hcVerbiage.previous[me.language]}
                </button>
              </Link>
            )}
            {!!nextPrevIds.nextId && (
              <Link to={`/advice/${nextPrevIds.nextId}`}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  style={{
                    backgroundColor: me?.clinic?.primaryColor,
                  }}
                >
                  {hcVerbiage.next[me.language]}
                  <ArrowRightCircleIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </Link>
            )}
          </>
        }
      />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
        <div className="flex flex-col w-full">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6 flex flex-col gap-10">
              {!!advice?.video && <ResponsivePlayer url={advice.video} />}
              {!!advice?.descr && (
                <InfoItemHtml
                  className="text-gray-500 normal-styling"
                  value={advice.descr}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advice;
