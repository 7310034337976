/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * [2023-08-01.1240 by Brian]
 *    Added support for toast names and removing toasts by that name.
 *    This provides the ability to close one or more toasts at the same
 *    time. The id of the toast is not always easy to capture and use
 *    even though it is returned by createToast.
 *
 *    I also disabled the ESLINT error about empty functions.
 */
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type ToastTimeouts = {
  [key: string]: NodeJS.Timeout[];
};

export enum ToastTypes {
  Success,
  Fail,
  Info,
}

export type IToast = {
  title: string;
  description: React.ReactNode;
  type: ToastTypes;
  duration?: number;
  id?: string;
  open?: boolean;
  name?: string;
  stack?: any;
};

export const ToastContext = React.createContext<{
  toasts: IToast[];
  createToast: (data: IToast) => string;
  removeToast: (id: string) => void;
  removeToastByName: (name: string) => void;
  resetToastDuration: (id: string, duration: number) => void;
  removeToastTimeout: (id: string) => void;
  name?: string;
  isToast: boolean;
}>({
  toasts: [],
  createToast: (): string => {
    return '';
  },
  removeToast: () => {},
  resetToastDuration: () => {},
  removeToastTimeout: () => {},
  removeToastByName: () => {},
  isToast: false,
});

type Props = {
  children?: React.ReactNode;
};

export const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [toastTimeouts, setToastTimeouts] = useState<ToastTimeouts>({});
  const [isToast, setIsToast] = useState<boolean>(false);

  useEffect(() => {
    if (toasts.length && toasts.every((toast) => toast.open === false)) {
      setToasts([]);
      setIsToast(false);
    }
    if (!toasts.length) {
      setIsToast(false);
    }
  }, [toasts]);

  const removeToast = useCallback((id: string) => {
    if (!id) return;
    setToasts((prev) => {
      return prev.map((item) =>
        item.id === id
          ? {
              ...item,
              open: false,
            }
          : item,
      );
    });
  }, []);

  const removeToastByName = useCallback((name: string) => {
    if (!name) return;
    setToasts((prev) => {
      return prev.map((item) =>
        item.name === name
          ? {
              ...item,
              open: false,
            }
          : item,
      );
    });
  }, []);

  const scheduleToastRemoval = useCallback(
    (id: string, duration: number) => {
      const timeout = setTimeout(() => {
        removeToast(id);
      }, duration);
      setToastTimeouts((prev) => ({
        ...prev,
        [id]: [...(prev[id] || []), timeout],
      }));
    },
    [removeToast],
  );

  const createToast = (toast: IToast) => {
    if (toast.description) {
      const descr = toast.description as any;
      if (typeof descr !== 'string') {
        if (typeof descr?.response?.data?.message === 'string') {
          toast.description = descr.response.data.message;
        } else if (typeof descr?.message === 'string') {
          toast.description = descr.message;
        }
      }
    }
    setIsToast(true);
    const id = uuidv4();
    setToasts((prev) => [...prev, { ...toast, id, open: true }]);
    if (toast.duration) {
      scheduleToastRemoval(id, toast.duration);
    }
    return id;
  };

  const resetToastDuration = useCallback(
    (id: string, duration: number) => {
      scheduleToastRemoval(id, duration);
    },
    [scheduleToastRemoval],
  );

  const removeToastTimeout = (id: string) => {
    const timeouts = toastTimeouts[id];
    if (timeouts) {
      timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    }
    setToastTimeouts((prev) => {
      delete prev[id];
      return prev;
    });
  };

  return (
    <ToastContext.Provider
      value={{
        toasts,
        createToast,
        removeToast,
        removeToastByName,
        resetToastDuration,
        removeToastTimeout,
        isToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
