import Code from '@chiroup/core/functions/Code';
import tiptapExtensionsBase from '@chiroup/core/functions/tiptapExtensionsBase';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import { emojiSuggestion } from './emojiSuggestion';

export const tiptapExtensions = [
  ...tiptapExtensionsBase,
  // Placeholder.configure({
  //   placeholder: 'Enter a description...',
  // }),
  Code,
  Emoji.configure({
    emojis: gitHubEmojis,
    enableEmoticons: true,
    suggestion: emojiSuggestion,
  }),
];
