export type ErrorMessageFieldErrors = {
  [key: string]: { message: string; code?: string };
};

export type ErrorMessage =
  | { message: string; code?: string }
  | { fieldErrors: ErrorMessageFieldErrors }
  | string;
type ErrorMessageBody =
  | { message: string; code?: string }
  | { fieldErrors: ErrorMessageFieldErrors };

export class BadRequestError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(error: ErrorMessage) {
    super(JSON.stringify(error));
    this.statusCode = 400;
    this.body = typeof error === 'string' ? { message: error } : error;
  }
}

export class UnauthorizedError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(message: string) {
    super(message);
    this.statusCode = 401;
    this.body = { message };
  }
}

export class ForbiddenError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(message: string) {
    super(message);
    this.statusCode = 403;
    this.body = { message };
  }
}

export class NotFoundError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(message: string) {
    super(message);
    this.statusCode = 404;
    this.body = { message };
  }
}

export class ServerError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(error: ErrorMessage) {
    super(JSON.stringify(error));
    //TODO: This was a 500 error before, temporarily changing until amplify fixes their retry issue
    this.statusCode = 422;
    this.body = typeof error === 'string' ? { message: error } : error;
  }
}

export type MockErrorConfig = {
  statusCode: number;
  message: string;
};

export class MockError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(mock: MockErrorConfig) {
    super(mock.message);
    this.statusCode = mock.statusCode;
    this.body = { message: mock.message };
  }
}

export class TimeoutError extends Error {
  statusCode: number;

  body: ErrorMessageBody;

  constructor(error: ErrorMessage) {
    super(JSON.stringify(error));
    this.statusCode = 504;
    this.body = typeof error === 'string' ? { message: error } : error;
  }
}
