import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useContext } from 'react';
import { MeContext } from '../contexts/me.context';

const UserInfo = () => {
  const { me } = useContext(MeContext);

  return (
    <div className="flex items-center px-5">
      <div className="flex-shrink-0">
        <UserCircleIcon className="h-10 w-10 text-gray-600" />
      </div>
      <div className="ml-3">
        <div className="text-base font-medium text-gray-800">
          {me.fname}
          {me.mname ? ` ${me.mname}` : ''}
          {me.lname ? ` ${me.lname}` : ''}
        </div>
        <div className="text-sm font-medium text-gray-500">{me.username}</div>
      </div>
    </div>
  );
};

export default UserInfo;
