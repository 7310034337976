import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { IToast, ToastTypes } from '../../contexts/toast.context';

type Props = {
  toast: IToast;
  removeToast: (id: string) => void;
  resetToastDuration: (id: string, duration: number) => void;
  removeToastTimeout: (id: string) => void;
};

const Toast: React.FC<Props> = ({
  toast,
  removeToast,
  resetToastDuration,
  removeToastTimeout,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    if (toast.duration && toast.id && isHovered) {
      removeToastTimeout(toast.id);
    }
  }, [isHovered, toast.duration, removeToastTimeout, toast.id]);

  useEffect(() => {
    if (toast.duration && toast.id && !isHovered) {
      resetToastDuration(toast.id, toast.duration);
    }
  }, [isHovered, toast.duration, resetToastDuration, toast.id]);

  const close = () => {
    if (toast.id) {
      removeToast(toast.id);
    }
  };

  return (
    <Transition
      show={!!toast.open}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto print:hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {toast.type === ToastTypes.Success ? (
                  <svg
                    className="h-6 w-6 text-primary-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ) : toast.type === ToastTypes.Fail ? (
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ) : null}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm leading-5 font-medium text-gray-900">
                  {toast.title}
                </p>
                <p className="mt-1 text-sm leading-5 text-gray-500">
                  {toast.description}
                </p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  onClick={close}
                  className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Toast;
