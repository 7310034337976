import React from 'react';
import { HealthcomMe } from '../hooks/useMe';
import { Languages } from '@chiroup/core/enums/Languages.enum';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';

export const MeContext = React.createContext<{
  me: HealthcomMe;
  updateMe: (key: keyof HealthcomMe, value: ValueOf<HealthcomMe>) => void;
}>({
  me: {
    ID: '',
    email: '',
    fname: '',
    mname: '',
    lname: '',
    language: Languages.en,
    username: '',
    advice: [],
    conditions: [],
    exercises: [],
    clinic: {},
    unsubscribe: 0,
    primaryClinician: '',
  },
  updateMe: () => {},
});
